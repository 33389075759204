import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FPEContext, FPEQuestionObj } from '../../components/fpe/fpeContext';
import { sendQuestionData } from '../../services/opt-out-service';
import  { downloadFile, removeFile, uploadFile, getFileList } from '../../services/fpe-service';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import RichText from '../../components/richTextEditor/rich-text-editor';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadIcon from '@mui/icons-material/UploadFileOutlined';

import { FileUpload } from '../../components/file/fileUpload';
import FileList from '../../components/file/fileList';
import { getBaseUrl } from '../../services/auth-service';

export default function UPSOMFPEForm () {
    const params = useParams();

    const { fpeState, fpeDispatch } = useContext(FPEContext);
    const [formObj, setFormObj] = useState<{[key: string]: any}>({});
    const [selectedFile, setSelectedFile] = useState<any>(null);

    const richTextChange = (text: string, content: string, questionObj: FPEQuestionObj) => {
            const formSectionData = fpeState.upsomUppFpeData;

            if (!formSectionData) return;

                if (!formObj[questionObj.formId]) {
                    setFormObj({...formObj, [questionObj.formId]: content });
                    sendQuestionData(questionObj.link, text, !text ? '' : content);
                } else if (formObj[questionObj.formId] !== content) {
                        setFormObj({...formObj, [questionObj.formId]: content });
                        sendQuestionData(questionObj.link, text, !text ? '' : content);
                }


            formSectionData.questions = formSectionData?.questions.map(q => {
                if (q.formId === questionObj.formId) {
                    questionObj.response = !text ? null : content;
                    if (!!text && questionObj.required && questionObj.error) delete questionObj.error;
                }
                return q;
            });
            
            fpeDispatch({
                type: "SET_FPE_FORM_SECTION",
                payload: {
                    section: "upsomUppFpeData",
                    data: formSectionData
                }
            });
    }
    
    const renderQuestions = () => {
        if (!fpeState.upsomUppFpeData?.questions) return null;

        return fpeState.upsomUppFpeData?.questions.map(questionObj => {

            return (
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                        <RichText
                            onChange={(text: string, content: string) => richTextChange(text, content, questionObj)}
                            id={questionObj.questionId}
                            label={questionObj.text}
                            required={questionObj.required}
                            info={questionObj.helpText}
                            value={questionObj.response}
                            readOnly={questionObj.disabled || !questionObj.editable}
                            error={questionObj.error}
                        />
                    </FormControl>
                </Grid>
            );
        });

    }

    const updateSectionFileList = (res: any) => {
        if (res.status === 200) {
            const sectionData = {...fpeState.upsomUppFpeData};

            sectionData.files = res.data;

            fpeDispatch({
                type: "SET_FPE_FORM_SECTION",
                payload: {
                    section: "upsomUppFpeData",
                    data: sectionData
                }
            });
        }
    }

    const submitForm = async (e: any) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", selectedFile?.name);

        //@ts-ignore
        formData.append("file", selectedFile);
      
        await uploadFile(`${getBaseUrl()}/attach/upload/${params.year}/${fpeState?.formType}/${fpeState?.formId}/${fpeState.upsomUppFpeData?.id}`, formData);
        resetList();

      };

      const resetList = async () => {
        const formListRes = await getFileList(fpeState?.formId, fpeState?.formType, params.year, fpeState.upsomUppFpeData?.id);
        updateSectionFileList(formListRes);
    }

    const fileListProps = fpeState?.upsomUppFpeData?.canAttach ? {
        download: downloadFile,
        delete: removeFile,
        callback: resetList
    } : {download: downloadFile};

    return (
        <Accordion
            expanded={fpeState.firstOpen}
            onChange={() => {
                fpeDispatch({
                    type: "SET_SECTION_OPEN",
                    payload: {
                        type: "firstOpen",
                        status: !fpeState.firstOpen
                    }
                });
            }}
        >
                    <AccordionSummary
                        sx={{
                            color: '#004AA0',
                            fontSize: '20px',
                            borderBottom: '1px solid #e9e9e9',
                            minHeight: '100px'
                        }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography fontSize={20} variant="h5">UPSOM and UPP Faculty Performance Evaluation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                        component="form"
                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                        noValidate
                        autoComplete="off"
                        >
                            <Grid container spacing={[2, 2]} columnSpacing={[2, 2]}> 
                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={!!fpeState?.fpeUserEvalData?.name} 
                                            htmlFor="component-outlined"
                                        >
                                            Name
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            required
                                            label="Name"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.name}
                                            notched={!!fpeState?.fpeUserEvalData?.name}
                                        />
                                    </FormControl>
                                </Grid>                           
                            
                            
                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={!!fpeState?.fpeUserEvalData?.degree}
                                            htmlFor="component-outlined">
                                                Degree
                                            </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Degree"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.degree}
                                            notched={!!fpeState?.fpeUserEvalData?.degree}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            shrink={!!fpeState?.fpeUserEvalData?.payCategory}
                                            htmlFor="component-outlined">
                                                Pay Category
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Pay Category"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.payCategory}
                                            notched={!!fpeState?.fpeUserEvalData?.payCategory}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.fpeUserEvalData?.facultyType}>
                                                Faculty Type
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Faculty Type"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.facultyType}
                                            notched={!!fpeState?.fpeUserEvalData?.facultyType}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.fpeUserEvalData?.rank}>
                                            Academic Rank
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Academic Rank"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.rank}
                                            notched={!!fpeState?.fpeUserEvalData?.rank}
                                        />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                        shrink={!!fpeState?.fpeUserEvalData?.department}
                                        htmlFor="component-outlined">
                                            Department
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Department"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.department}
                                            notched={!!fpeState?.fpeUserEvalData?.department}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={!!fpeState?.fpeUserEvalData?.tenureStatus}
                                            htmlFor="component-outlined">
                                            Tenure Status
                                            </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Tenure Status"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.tenureStatus}
                                            notched={!!fpeState?.fpeUserEvalData?.tenureStatus}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.fpeUserEvalData?.primaryEvaluator}>
                                                Primary Evaluator
                                            </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Primary Evaluator"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.primaryEvaluator}
                                            notched={!!fpeState?.fpeUserEvalData?.primaryEvaluator}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                        shrink={!!fpeState?.fpeUserEvalData?.division}
                                        htmlFor="component-outlined">
                                            Division
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Division"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.division}
                                            notched={!!fpeState?.fpeUserEvalData?.division}
                                        />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={true}
                                            htmlFor="component-outlined">
                                                Other Evaluator
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Other Evaluator"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.otherEvaluator || "N/A"}
                                            notched={true}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.fpeUserEvalData?.departmentChair}>
                                                Chair / Final Evaluator
                                            </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            label="Chair / Final Evaluator"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.departmentChair}
                                            notched={!!fpeState?.fpeUserEvalData?.departmentChair}
                                        />
                                    </FormControl>
                                </Grid>

                                { renderQuestions() }

                                {fpeState?.upsomUppFpeData?.canAttach &&
                                <FormControl sx={{ m: 2 }} fullWidth>
                                <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                        <FileUpload
                                            onFileSelectSuccess={(file: any) => setSelectedFile(file)}
                                            onFileSelectError={({ error }: { error: Error}) => alert(error)}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                        <Button disabled={!selectedFile} startIcon={<UploadIcon />} onClick={submitForm}>Submit</Button>
                                        </Grid>
                                </Grid>
                                    
                                    
                                    
                                </FormControl>
                                }
                                
                            <FileList 
                                data={{
                                    formId: fpeState?.formId,
                                    formType: fpeState.formType,
                                    section: fpeState.upsomUppFpeData?.id,
                                    year: params.year
                                }}
                                files={fpeState.upsomUppFpeData?.files} 
                                {...fileListProps}
                            />
                            
                                
                            </Grid>

                        </Box>
                    </AccordionDetails>
                </Accordion>
    );
}