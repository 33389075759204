import React, { useContext, useEffect, useState } from 'react';

import { getDepartmentList, getDivisionList, getFacultyList, getTodoAllEvaluations, getTodoCurrentYearEvaluations, getTodoNowEvaluations } from '../../services/todo-service';
import { AccountContext } from '../../components/account/accountContext';
import { AuthContext } from '../../components/auth/authContext';

import TableRows from './table-rows';

import { useTheme } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { Alert, Box, ButtonGroup, Button, CircularProgress, FormControl, Grid, InputLabel, Select, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Search from '@mui/icons-material/Search';

type EvalTypeOptionType = {label: string, value: number};

interface LoadingDataType {
    [x: string]: boolean
}

interface OpenDataType {
    [x: string]: boolean
}

type SearchDataType = {
    [x: string]: string
}

type FilterDataType = {
    [x: string]: FilterObjectType
}

type FilterObjectType = { 
    type: string, 
    options: {
        [x: string]: string
    },
    url?: string,
    param?: string
};

interface SearchObj {
    key: string;
    value: string;
}  

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

export default function EvaluationList() {
    const [loading, setLoading] = useState(false);
    const { accountState, accountDispatch } = useContext(AccountContext);
    const { token } = useContext(AuthContext);
    const [selectedEvalType, setSelectedEvalType] = useState<number>(0);
    const [filters, setFilters] = useState<FilterDataType>({});
    const [selectedFilters, setSelectedFilters] = useState<any>({});

    const [facultySearchOptions, setFacultySearchOptions] = useState<readonly SearchObj[]>([]);
    const [departmentSearchOptions, setDepartmentSearchOptions] = useState<readonly SearchObj[]>([]);
    const [divisionSearchOptions, setDivisionSearchOptions] = useState<readonly SearchObj[]>([]);

    const [open, setOpen] = useState<OpenDataType>({});
    const [loadingSearch, setLoadingSearch] = useState<LoadingDataType>({});
    const [searchObjectVal, setSearchObjectVal] = useState<SearchDataType>({});

    const [filterMessage, setFilterMessage] = useState(undefined);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [paginationCount, setPaginationCount] = useState(0);

    const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {    
        if (loadingSearch['faculty']) {
          return undefined;
        }
            
        if (
            filters['faculty'] && 
            filters['faculty'].url &&
            filters['faculty'].param && 
            searchObjectVal?.faculty &&
            String(searchObjectVal?.faculty).length > 2) {
            (async () => {
                const res = await getFacultyList(String(filters.faculty.url), searchObjectVal?.faculty);
    
                setLoadingSearch({ ...loadingSearch, faculty: true});
                if (res?.status === 200) {
                    setPage(0);
                    setFacultySearchOptions(res?.data ? [...res?.data] : []);
                }
    
                setLoadingSearch({ ...loadingSearch, faculty: false});
    
            })();
        }
        
    
      }, [searchObjectVal.faculty]);


      useEffect(() => {    
        if (loadingSearch['department']) {
          return undefined;
        }

        if (
            filters['department'] && 
            filters['department'].url &&
            filters['department'].param && 
            searchObjectVal?.department &&
            String(searchObjectVal.department).length > 2) {
            (async () => {
                const res = await getDepartmentList(String(filters.department.url), searchObjectVal.department);
    
                setLoadingSearch({ ...loadingSearch, department: true});
                if (res?.status === 200) {
                    setPage(0);
                    setDepartmentSearchOptions(res?.data ? [...res?.data] : []);
                }
    
                setLoadingSearch({ ...loadingSearch, department: false});
    
            })();
        }
        
    
      }, [searchObjectVal.department]);

      useEffect(() => {    
        if (loadingSearch['division']) {
          return undefined;
        }

        if (
            filters['division'] && 
            filters['division'].url &&
            filters['division'].param && 
            searchObjectVal?.division &&
            String(searchObjectVal.division).length > 2) {
            (async () => {
                const res = await getDivisionList(String(filters.division.url), searchObjectVal.division);
    
                setLoadingSearch({ ...loadingSearch, division: true});
                if (res?.status === 200) {
                    setPage(0);
                    setDivisionSearchOptions(res?.data ? [...res?.data] : []);
                }
    
                setLoadingSearch({ ...loadingSearch, division: false});
    
            })();
        }
        
    
      }, [searchObjectVal.division]);
      
    const evalTypeOptions: EvalTypeOptionType[] = [
        {
            label: "Action Needed",
            value: 0
        },
        {
            label: "My Forms",
            value: 1
        },
        {
            label: "Current Year Forms",
            value: 2
        },
    ];

    useEffect(() => {

        const getFilterParams = () => {
            let queryParams;

            if (selectedFilters.department) queryParams = `department=${selectedFilters?.department?.key || selectedFilters.department}`;
            if (selectedFilters.division) queryParams = queryParams ? `${queryParams}&division=${selectedFilters.division.key || selectedFilters.division}` : `division=${selectedFilters.division.key || selectedFilters.division}`;
            if (selectedFilters.status) queryParams = queryParams ? `${queryParams}&status=${selectedFilters.status}` : `status=${selectedFilters.status}`;
            if (selectedFilters.faculty) queryParams = queryParams ? `${queryParams}&faculty=${selectedFilters.faculty.key || selectedFilters.faculty}` : `faculty=${selectedFilters.faculty.key || selectedFilters.faculty}`;
            if (selectedFilters.year) queryParams = queryParams ? `${queryParams}&year=${selectedFilters.year.key || selectedFilters.year}` : `year=${selectedFilters.year.key || selectedFilters.year}`;
            return queryParams;
        }

        const getPageParams = () => `page=${page + 1}&n=${rowsPerPage}`;

        const getApiCall = () => {
            switch(selectedEvalType){
                case 0:
                    return getTodoNowEvaluations;
                case 1: 
                    return getTodoCurrentYearEvaluations;
                case 2:
                    return getTodoAllEvaluations;
                default:
                    return getTodoNowEvaluations;
            }
        }

        const getData = async () => {
            setLoading(true);

            const apiCall = getApiCall();
            let queryParams = getFilterParams();
            queryParams = queryParams ? `${queryParams}&${getPageParams()}` : getPageParams();
            const res = await apiCall(token || '', queryParams);

            if (res?.status === 200 && res?.data && res.data.forms) {

                accountDispatch({
                    type: "SET_TITLE",
                    payload: res.data.title
                });

                accountDispatch({
                    type: "SET_EVAL_LIST_DATA",
                    payload: res.data.forms
                });

                setFilters(res.data.filters);
                setFilterMessage(res.data.message);
                setPaginationCount(res.data.pagination.count);
            }

            setLoading(false);
        }

          
          getData();
          

    }, [selectedEvalType, page, rowsPerPage, selectedFilters.department, selectedFilters.division, selectedFilters.status, selectedFilters.faculty, selectedFilters.year]);

    useEffect(() => {

    
        if (!selectedFilters.year && filters.year) {
            const preSelectedYear = Object.keys(filters.year.options).find(key => key === '2024');
            setSelectedFilters({...selectedFilters, year: preSelectedYear});
        }

    }, [filters]);

    const getSearchOptions = (filterType: string) => {
        if (filterType === 'faculty') {
            return facultySearchOptions;
        } else if (filterType === 'department') {
            return departmentSearchOptions;
        }

        return divisionSearchOptions;
    }

    const changeEvalType = (val: number) => {
        setSelectedEvalType(val as number);
    }

    const handleFilterChange = (event: SelectChangeEvent, filterType: string) => {
        const value = event.target.value as string;
        setPage(0);
        setSelectedFilters({...selectedFilters, [filterType]: value});
    }

    const onSearchSelect = (event: object, value: any, filterType: string) => {
        setSelectedFilters({...selectedFilters, [filterType]: value});
    }

    const onSearchChange = (event: React.SyntheticEvent, value: string, reason: string, filterType: string) => { 
        setSearchObjectVal({...searchObjectVal, [filterType]: value});
    }

    const renderAutoComplete = (filterKeyType: string) => {

        return (
           <Grid item xs={12} md={6} lg={2.4}>
                <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    open={open[filterKeyType]}
                    onOpen={() => {
                        setOpen({ ...open, [filterKeyType]: true});
                    }}
                    onClose={() => {
                        setOpen({ ...open, [filterKeyType]: true});
                    }}
                    sx={{
                        '& .MuiAutocomplete-inputRoot': {
                            borderRadius: 0
                        }
                    }}
                    forcePopupIcon={false}
                    value={selectedFilters[filterKeyType] || null}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    getOptionLabel={(option) => option.value}
                    options={getSearchOptions(filterKeyType)}
                    loading={loadingSearch[filterKeyType]}
                    onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => onSearchChange(event, value, reason, filterKeyType)}
                    onChange={(e, value) => onSearchSelect(e, value, filterKeyType)}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label={`Search ${filterKeyType.charAt(0).toUpperCase()}${filterKeyType.slice(1)}...`}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {loadingSearch[filterKeyType] ? <CircularProgress color="inherit" size={20} /> : null}
                                <Search />
                            </React.Fragment>
                            ),
                        }}
                        />
                    )}
                />
           </Grid> 
        );
    }

    const renderFilters = () => {
        if (!Object.keys(filters)) return (<></>);

        return Object.keys(filters).map(filterKey => {
            if (filters[filterKey].type === 'select' && filters[filterKey].options) {
                return (
                    <Grid item xs={12} md={6} lg={2.4}>
                        <FormControl 
                            fullWidth
                            sx={{
                                border: 'none'
                            }}>
                            <InputLabel id="demo-simple-select-label">{`${filterKey.charAt(0).toUpperCase()}${filterKey.slice(1)}`}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedFilters[filterKey] || ''}
                                fullWidth
                                label="Age"
                                onChange={(e) => handleFilterChange(e, filterKey)}
                                sx={{
                                    borderRadius: 0
                                }}
                            >
                                {filters[filterKey].options &&
                                    Object.keys(filters[filterKey].options).map((optionKey) => (
                                        <MenuItem value={optionKey}>{filters[filterKey].options[optionKey]}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                );
            } else if (filters[filterKey].type === 'typeahead') {
                return renderAutoComplete(filterKey);
            }
            
        });
        
        return (<></>)
    }

    return (
        <React.Fragment>
            {
                <>
                <Grid container marginBottom={2} spacing={[2, 2]}>
                    <Grid item sm={12} md={12} lg={12}>
                        <FormControl variant="outlined" fullWidth sx={{ height: "50px" }}>
                            <ButtonGroup 
                                variant="contained" 
                                fullWidth 
                                aria-label="Basic button group" 
                                sx={{ height: "100%", borderRadius: 0, boxShadow: 'none' }}>
                            {
                                evalTypeOptions.map((evalType: EvalTypeOptionType) => (
                                    <Button 
                                        disabled={loading} 
                                        sx={{ border: 'none', backgroundColor: selectedEvalType === evalType.value ? '#ffbc00' : 'transparent' }} 
                                        variant="outlined" 
                                        onClick={() => changeEvalType(evalType.value)}> 
                                            {evalType.label} 
                                    </Button>
                                ))
                            }
                            </ButtonGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container marginBottom={2} spacing={1}>
                    { renderFilters() }
                </Grid>

                <Grid container marginBottom={10} spacing={[2, 2]}>
                    <Grid item xs={12} md={12} lg={4}>
                        <Button 
                            sx={{ height: '100%'}}
                            fullWidth
                            variant="outlined" 
                            startIcon={<ClearIcon />}
                            onClick={() => {
                                setSelectedFilters({})
                                setSearchObjectVal({})
                            }}> 
                                Clear Filter
                        </Button>
                    </Grid>
                </Grid>
                </>
            }
            {loading && <CircularProgress />}
            {!loading && filterMessage &&
                <Alert severity="success" sx={{ marginBottom: "20px" }}>
                    {filterMessage}
                </Alert>
            }
            {!loading && accountState.evaluationListData && accountState.evaluationListData.length > 0 &&
                <Box sx={{ width: '100%' }} border="1px solid #e9e9e9">
                    <TableContainer>
                        <Table aria-label="customized table">
                        <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={paginationCount}
                                    rowsPerPage={rowsPerPage}
                                    component="div"
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                                </TableRow>
                            <TableBody>
                                <TableRows data={accountState.evaluationListData} />
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={paginationCount}
                                    rowsPerPage={rowsPerPage}
                                    component="div"
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </React.Fragment>
    );
}