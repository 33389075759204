import  { getFPEFormData } from '../../services/fpe-service';

export const getFormData = async (data: {
    formId?: string, 
    year?: string, 
    link?: string,
    token: string | null,
    form: string
}, fpeDispatch: React.Dispatch<any>) => {

    const res = await getFPEFormData(data.formId, data.year, data.link, data.token || "", data.form);

    if (res?.status === 200) {

        fpeDispatch({
            type: "SET_FPE_GENERAL_DATA",
            payload: {
                formId: res.data?.formId, 
                formType: res.data?.formType,
                formStatus: res.data?.status,
                canDelegate: res.data?.canDelegate,
                submitOptions: res.data?.submitOptions,
                title: res.data?.title
            }
        });

        fpeDispatch({
            type: "SET_FPE_EVAL_USER_FORM",
            payload: {
                ...res.data?.header
            }
        });


        fpeDispatch({
            type: "SET_FPE_FORM_SECTIONS",
            payload: res.data?.sections
        });
    }
}