import { InitialState, FPEFormSectionObj } from './fpeContext';

type FPESectionBatchObj=  {
    upsomUppFpeData?: FPEFormSectionObj,
    researchScholarData?: FPEFormSectionObj,
    teachingData?: FPEFormSectionObj,
    clinicalData?: FPEFormSectionObj,
    serviceAdminData?: FPEFormSectionObj,
    overallAssessmentData?: FPEFormSectionObj
    signaturesData?: FPEFormSectionObj
};

type OptSectionBatchObj=  {
    optMainData?: FPEFormSectionObj
};


const FPEReducer = (state: InitialState, action: { type: any, payload: any}) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                points: action.payload
            }
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "SET_ALL_OPEN":
            return {
                ...state,
                allOpen: action.payload,
                firstOpen: action.payload,
                secondOpen: action.payload,
                thirdOpen: action.payload,
                fourthOpen: action.payload,
                fifthOpen: action.payload,
                sixthOpen: action.payload
            }
        case "SET_SECTION_OPEN":
            return {
                ...state,
                [action.payload.type]: action.payload.status
            }
        case "SET_OPTOUT_FORM":
            return {
                ...state,
                optOutForm: action.payload
            }
        case "SET_OPTOUT_FORM_SECTIONS": 
            const sectionBatch = action.payload.reduce((batch: OptSectionBatchObj, section: any): OptSectionBatchObj => {
                if (section.id === "opt-main") batch["optMainData"] = section;
                return batch;
            }, {});

            
            return  {
                ...state,
                ...sectionBatch
            }
        case "SET_FPE_GENERAL_DATA":
            return {
                ...state,
                ...action.payload
            }
        case "SET_FPE_EVAL_USER_FORM":
            return {
                ...state,
                fpeUserEvalData: action.payload
            }

        case "SET_FPE_FORM_SECTION":
            return {
                ...state,
                [action.payload.section]: action.payload.data
            }
        case "SET_FPE_FORM_SECTIONS": 
            const sectionBatchObj = action.payload.reduce((batch: FPESectionBatchObj, section: any): FPESectionBatchObj => {
                
                if (section.id === "fpe-cover-page") batch["upsomUppFpeData"] = section;
                if (section.id === "fpe-research") batch["researchScholarData"] = section;
                if (section.id === "fpe-teaching") batch["teachingData"] = section;
                if (section.id === "fpe-clinical") batch["clinicalData"] = section;
                if (section.id === "fpe-service") batch["serviceAdminData"] = section;
                if (section.id === "fpe-overall") batch["overallAssessmentData"] = section;
                if (section.id === "fpe-signatures") batch["signaturesData"] = section;
                
                return batch;
            }, {});
            
            return  {
                ...state,
                ...sectionBatchObj
            }
        case "SET_FPE_FORM_TEXT_SECTION":

            const updateSectionTextQuestion = (section: {[x:string]: any}) => {
                const { questionId, roleId } = action.payload;

                return section.questions.map((q: any) => {
                    if (q.questionId === questionId && q.roleId === roleId) {
                        q.response = action.payload.value;
                    }

                    return q;
                });
            }

            const stateObj: {[x:string]: any} = {...state};

            const newQuestions = updateSectionTextQuestion(stateObj[action.payload.section])

            return {
                ...state,
                [action.payload.section]: { ...stateObj[action.payload.section], questions: newQuestions }
            }
        case "SET_OPEN_CONFIRMATION":
            return {
                ...state,
                openConfimation: action.payload
            }
        case "SET_SUBMIT_LINK":
            return {
                ...state,
                chosenSubmitLink: action.payload
            }
        default:
            return state;
    }
}

export default FPEReducer;