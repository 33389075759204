import React, { useContext, useState } from 'react';

import { useParams } from 'react-router-dom';

import { AuthContext } from '../../components/auth/authContext';
import { getFormData } from '../../components/fpe/fpeAction';
import { FPEContext, FPEFormSectionObj, FPEQuestionObj } from '../../components/fpe/fpeContext';

import { sendQuestionData } from '../../services/opt-out-service';
import  { downloadFile, removeFile, uploadFile, getFileList } from '../../services/fpe-service';
import { getBaseUrl } from '../../services/auth-service';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import LinearProgress from '@mui/material/LinearProgress';

import RichText from '../../components/richTextEditor/rich-text-editor';
import InfoTooltip from '../../components/infoTooltip/infoTooltip';
import FileList from '../../components/file/fileList';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadIcon from '@mui/icons-material/UploadFileOutlined';

import { FileUpload } from '../../components/file/fileUpload';

export default function ResearchAndScholarForm () {
    const params = useParams();

    const { token } = useContext(AuthContext);
    const { fpeState, fpeDispatch } = useContext(FPEContext);

    const [formObj, setFormObj] = useState<{[key: string]: any}>({});
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const valueChange = async (value: string, questionObj: FPEQuestionObj) => {
        const formSectionData = fpeState.researchScholarData;
        const questionObjId = `${questionObj.questionId}-${questionObj.roleId}`;

        if (!formSectionData) return;

        setLoading(true);

        if (!formObj[questionObjId]) {
            setFormObj({...formObj, [questionObjId]: value });
            await sendQuestionData(questionObj.link, value, value);
        } else if (formObj[questionObjId] !== value) {
                setFormObj({...formObj, [questionObjId]: value });
                await sendQuestionData(questionObj.link, value, value);
        }

        /*formSectionData.questions = formSectionData.questions.map(q => {
            const qObjId = `${q.questionId}-${q.roleId}`;

            if (qObjId === questionObjId) {
                questionObj.response = value;

                if (!!value && questionObj.required && questionObj.error) delete questionObj.error;
                
            }

            return q;
        });*/

        await getFormData({ link: fpeState.fpeUserEvalData?.formUrl, token, form: fpeState.fpeUserEvalData?.formType as string }, fpeDispatch);

        setLoading(false);
    }    

    const richTextChange = (text: string, content: string, questionObj: FPEQuestionObj) => {
        const formSectionData = fpeState.researchScholarData;
        const questionObjId = `${questionObj.questionId}-${questionObj.roleId}`;

        if (!formSectionData) return;

            if (!formObj[questionObjId]) {
                setFormObj({...formObj, [questionObjId]: content });
                sendQuestionData(questionObj.link, text, !text ? '' : content);
            } else if (formObj[questionObjId] !== content) {
                    setFormObj({...formObj, [questionObjId]: content });
                    sendQuestionData(questionObj.link, text, !text ? '' : content);
            }

        formSectionData.questions = formSectionData?.questions.map(q => {
            const qObjId = `${q.questionId}-${q.roleId}`;

            if (qObjId === questionObjId) {
                questionObj.response = !text ? null : content;
                if (!!text && questionObj.required && questionObj.error) delete questionObj.error;
            }
            return q;
        });

        fpeDispatch({
            type: "SET_FPE_FORM_TEXT_SECTION",
            payload: {
                section: "researchScholarData",
                value: !text ? null : content,
                questionId: questionObj.questionId,
                roleId: questionObj.roleId
            }
        });
}

    const renderQuestions = () => {
        if (!fpeState.researchScholarData?.questions) return null;

        return fpeState.researchScholarData?.questions.map(questionObj => {
            const questionObjId = `${questionObj.questionId}-${questionObj.roleId}`;

            if (questionObj.type === 'yes-no' || questionObj.type === 'satisfaction') {
                return (
                    <FormControl margin="dense" error={Boolean(questionObj.error)} fullWidth variant="outlined">
                        <FormLabel 
                            required={questionObj.required} 
                        >
                            { questionObj.text } 
                            { questionObj.helpText && <InfoTooltip title={questionObj.helpText} />}
                        </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name={questionObj.text}
                                        id={questionObjId}
                                        defaultValue={questionObj.response}
                                        onChange={(x, value) => valueChange(value, questionObj)}
                                    >
                                        {questionObj.options && questionObj.options.map(opt => (<FormControlLabel value={opt.value} control={<Radio disabled={!questionObj.editable}/>} label={opt.label} />))}
                                                 
                                    </RadioGroup>
                    </FormControl>
                );
            }

                return (
                    <Grid item xs={12}>
                        <FormControl margin="dense" fullWidth variant="outlined">
                            <RichText
                                onChange={function(text: string, content: string) {
                                    richTextChange(text, content, questionObj)
                                } }
                                id={questionObjId}
                                label={questionObj.text}
                                info={questionObj.helpText}
                                required={questionObj.required}
                                value={questionObj.response}
                                readOnly={questionObj.disabled || !questionObj.editable}
                                error={questionObj.error}
                            />
                        </FormControl>
                    </Grid>
                );
            
            
        });

    }

    const updateSectionFileList = (res: any) => {
        if (res.status === 200) {
            const sectionData = {...fpeState.researchScholarData};

            sectionData.files = res.data;

            fpeDispatch({
                type: "SET_FPE_FORM_SECTION",
                payload: {
                    section: "researchScholarData",
                    data: sectionData
                }
            });
        }
    }
      
        const submitForm = async (e: any) => {
            e.preventDefault();
            const formData = new FormData();
            formData.append("name", selectedFile?.name);

            //@ts-ignore
            formData.append("file", selectedFile);
          
            await uploadFile(`${getBaseUrl()}/attach/upload/${params.year}/${fpeState?.formType}/${fpeState?.formId}/${fpeState.researchScholarData?.id}`, formData);
            await resetList();

          };

        const resetList = async () => {
            const formListRes = await getFileList(fpeState?.formId, fpeState?.formType, params.year, fpeState.researchScholarData?.id);
            updateSectionFileList(formListRes);
        }
       
    const fileListProps = fpeState.researchScholarData?.canAttach ? {
        delete: removeFile,
        download: downloadFile,
        callback: resetList
    } : {download: downloadFile};

    return (
        <Accordion
            expanded={fpeState.secondOpen}
            onChange={() => {
                fpeDispatch({
                    type: "SET_SECTION_OPEN",
                    payload: {
                        type: "secondOpen",
                        status: !fpeState.secondOpen
                    }
                });
            }}
        >
                    <AccordionSummary
                    sx={{
                        color: '#004AA0',
                        fontSize: '20px',
                        borderBottom: '1px solid #e9e9e9',
                        minHeight: '100px'
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography fontSize={20} variant="h5">Research and Scholarly Activities</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ position: 'relative' }}>
                        <Box sx={{ width: '100%', position: 'absolute', top: 0, left: 0 }}>
                                {loading && <LinearProgress  />}
                        </Box>
                        <Box
                        component="form"
                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                        noValidate
                        autoComplete="off"
                        >
                            <div>
                    
                            { renderQuestions() }

                            {fpeState?.researchScholarData?.canAttach &&
                                <>
                                <FormControl sx={{ m: 1 }} fullWidth>
                                <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                        <FileUpload
                                            onFileSelectSuccess={(file: any) => setSelectedFile(file)}
                                            onFileSelectError={({ error }: { error: Error}) => alert(error)}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                        <Button disabled={!selectedFile} startIcon={<UploadIcon />} onClick={submitForm}>Submit</Button>
                                        </Grid>
                                </Grid>
                                </FormControl>
                                </>
                            }
                            <FileList 
                                data={{
                                    formId: fpeState?.formId,
                                    formType: fpeState.formType,
                                    section: fpeState.researchScholarData?.id,
                                    year: params.year
                                }}
                                files={fpeState.researchScholarData?.files} 
                                {...fileListProps}
                            />
                            
                            
                            </div>
                            

                        </Box>
                    </AccordionDetails>
                </Accordion>
    );
}