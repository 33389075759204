import { createContext, useState } from 'react';

import { getUserToken, getBaseUrl } from '../../services/auth-service';
import { getTodoData } from '../../services/todo-service';


type AuthContextType = { logoutUrl: string | null, authorities: string[] | null, token: string | null, userType: string | null, initAuths: string[], todoData?: {[x: string]: string},onLogin: () => void , onLogout: () => void, getTodoData: () => void  }

export const AuthContext = createContext<AuthContextType>({ logoutUrl: null, authorities: null, token: null, userType: null, initAuths: [], onLogin: () => {}, onLogout: () => {}, getTodoData: () => {} });

export const AuthProvider = ({ children }: any) => {

    const [token, setToken] = useState<null | string>(null);
    const [logoutUrl, setLogoutUrl] = useState<null | string>(null);
    const [authorities, setAuthorities] = useState<null | string[]>(null);
    const [userType, setUserType] = useState<null | string>(null);
    const [todoData, setTodoData] = useState<undefined | {[x: string]: string}>();
    const [initAuths] = useState(['FPEDEPT', 'DEPARTMENT', 'DIVISION', 'FACULTY_AFFAIRS']);

    const urlOrigin = window.location.host;

    const getToken = async () => {
        const localToken = localStorage.getItem('token');
        const localLogout = localStorage.getItem('logoutUrl');
        const localAuthorities = localStorage.getItem('authorities');
        const localUserType = localStorage.getItem('userType');

        if (localToken && localLogout) {
            setToken(localToken);
            setLogoutUrl(localLogout);
            setAuthorities(localAuthorities ? JSON.parse(localAuthorities) : null);
            setUserType(localUserType);

            return localToken;
        } else {
                const res = await getUserToken();

                console.log('token res - ', res);
    
                if (res?.status === 200) {
                    if (res?.data?.jwt) {
                        setToken(res.data.jwt);
                        setLogoutUrl(res.data.logout);
                        setAuthorities(res.data.authorities);
                        setUserType(res.data.name);

                        localStorage.setItem("token", res.data.jwt);
                        localStorage.setItem("logoutUrl", res.data.logout);
                        localStorage.setItem("userType", res.data.name);
                        localStorage.setItem("authorities", JSON.stringify(res.data.authorities));

                        return res.data.jwt;
                    }
                } else {
                    window.location.href = getBaseUrl() + '/login/creds/form';
                }
        }
        
    }

    const retreiveTodoData = async () => {
        const resTodoData = await getTodoData();
        setTodoData(resTodoData?.data);
    }

    const logOut = async () => {
        localStorage.clear();

        window.location.href = logoutUrl as string;
        return null;
    }
    
    return (
        <AuthContext.Provider value={{
            logoutUrl,
            authorities,
            token,
            userType,
            initAuths,
            getTodoData: retreiveTodoData,
            todoData: todoData,
            onLogin: getToken,
            onLogout: logOut
        }
    }>
        {children}
        </AuthContext.Provider>
    );
}