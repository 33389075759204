/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import ClassNames from 'classnames';
import LogoImage from './images/pitt-logo.png';
import Style from './logo.module.scss';

import { Link } from 'react-router-dom';

export function Logo(props: any){

  const css = ClassNames([

    Style.logo,
    props.className

  ]);

  const getImage = () => {

    return props.mark ? '' : LogoImage;
  }

  return (
    <Link to='/' className={ css }>
      <img src={ getImage() } alt='Logo' />
    </Link>
  )
}
