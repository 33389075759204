import React, { useContext } from 'react';

import InitializationList from './initialization-list';
import EvaluationList from './evaluation-list';
import OptOutList from './optout-list';

import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Typography from '@mui/material/Typography';
import { AccountContext } from '../../components/account/accountContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function HomeList() {
    const { accountState } = useContext(AccountContext);
      const [tabValue, setTabValue] = React.useState(0);

      const CustomTabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }

      const a11yProps = (index: number) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
      };

    return (
        <>
          <Box marginBottom={4} borderBottom="1px solid #e9e9e9" paddingBottom={1}>
            <Typography variant="h6" gutterBottom>
              {accountState.title}
            </Typography>
          </Box>
          

          
            <EvaluationList />
            {/*<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Initialization" {...a11yProps(0)} />
                <Tab label="Evaluation" {...a11yProps(1)} />
                {<Tab label="Opt Out" {...a11yProps(2)} />}
              </Tabs>
            </Box>
            
            <CustomTabPanel value={tabValue} index={0}>
              <InitializationList />
            </CustomTabPanel>
    `       
    
            <CustomTabPanel value={tabValue} index={1}>
              <EvaluationList />
            </CustomTabPanel>

            
              <CustomTabPanel value={tabValue} index={2}>
                <OptOutList />
              </CustomTabPanel>
            */}
            
        </>
    )
}