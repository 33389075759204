import React, { useContext, useEffect, useState } from 'react'
import Axios from 'axios';

import { Outlet } from "react-router-dom"

import { AuthContext } from '../../components/auth/authContext';

import GlobalStyles from '@mui/material/GlobalStyles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { AppNav } from '../../components/nav/app/app'
import { Logo } from '../../components/logo/logo';

import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import InventoryIcon from '@mui/icons-material/InventoryOutlined';
import HelpCenterIcon from '@mui/icons-material/HelpCenterOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';

import Styles from './root.module.scss';

const inputGlobalStyles = <GlobalStyles styles={{
  'button:not(.MuiButton-outlined)': {
    background: '#A0CCFF !important',
    color: '#004AA0',
    fontWeight: 600,
    border: '1px solid rgb(0 74 160 / 20%)'
  },
  '.MuiTableContainer-root': {
    minWidth: 700,
    border: '1px solid #e9e9e9'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgb(0 117 255 / 40%)!important'
  }
}} />

const drawerWidth = 240;

const mainAppBarStyles = { 
    background: '#ffffff', 
    borderBottom: '1px solid #e9e9e9', 
    boxShadow: 'none' 
}

export default function Root() {

  const { onLogin, getTodoData, token, authorities, todoData, logoutUrl, onLogout, initAuths } = useContext(AuthContext);
  const [logoutUrlRoute, setLogoutUrlRoute] = useState<string | undefined>(undefined);
  const [routes, setRoutes] = useState<{ label: string; icon: JSX.Element; func?: () => void, link?: string; }[]>([
    { label: 'Home',  icon: <HomeIcon />, link: '/'},
    { label: 'Help',  icon: <HelpCenterIcon />, link: '/help'}
  ]);

    useEffect(() => {
      const retreiveTodoData = () => {
        
      }
      const retreiveToken = () => {
        onLogin();
      }

      if (token === null) {
        retreiveToken();
      }

      if (token !== null) {
        Axios.defaults.headers.common['Authorization'] = token;

        if (!todoData) getTodoData();
      } 

      if (logoutUrl && !logoutUrlRoute) {
        setLogoutUrlRoute(logoutUrl);
        setRoutes([...routes,  { label: 'Log Out', icon: <LogoutIcon />, func: onLogout}]);
      }
      
      const isFacultyAffairs = authorities && authorities.find(a => initAuths.indexOf(a) > -1);

      if (todoData && todoData.initialization && isFacultyAffairs) {
        routes.splice(1, 0, { label: 'Initialization',  icon: <AnalyticsIcon />, link: '/initialization'});

        setRoutes([...routes]);
      }

    }, [token, logoutUrl, todoData]);

    if (token === null && !Axios.defaults.headers.common['Authorization'] ) {
      return (
        <>
          <p>Loading...</p>
        </>
      )
    }
    
    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ 
            zIndex: (theme) => theme.zIndex.drawer + 1, ...mainAppBarStyles }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap>
              <Logo />
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={Styles.Drawer}
          sx={{
            background: 'transparent',
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              background: '#ffffff',
              width: drawerWidth,
              boxSizing: 'border-box'
            },
            '@media print': {
              display: 'none'
            }
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          <Toolbar
            className={Styles.Toolbar}
            sx={{
              '& .MuiToolbar-root': {
                '@media (min-width: 780px)': {
                  display: 'none !important'
                }
              }
            }}
          >
            <Typography fontWeight={600} width={'100%'} align='center' sx={{fontSize: "16px !important", fontFamily: '"Qlassy", sans-serif !important' }}>
              School of Medicine
            </Typography>
          </Toolbar>
          <AppNav
            items={routes}
        />
          
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    )
}