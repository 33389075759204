import React from 'react';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { AccountProvider } from '../components/account/accountContext';
import { AuthProvider } from '../components/auth/authContext';

import './App.scss';

const Routes = createBrowserRouter([
  ...require('../routes/app').default
]);

function App() {
  return (
    
    <AuthProvider>
      <AccountProvider>
        <RouterProvider router={Routes} />
      </AccountProvider>
    </AuthProvider>
    
    
  );
}

export default App;
