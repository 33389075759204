import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../components/auth/authContext';
import { getFormData } from '../../components/fpe/fpeAction';
import { FPEContext, FPEFormSectionObj, FPEQuestionObj } from '../../components/fpe/fpeContext';
import { sendQuestionData } from '../../services/opt-out-service';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import InfoTooltip from '../../components/infoTooltip/infoTooltip';

import { renderEvaulationRateOptions, renderEvaluatorComments } from './fpe-general-form-elements';

import RichText from '../../components/richTextEditor/rich-text-editor';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FileUpload } from '../../components/file/fileUpload';

export default function OverallAssessmentForm () {
    const { token } = useContext(AuthContext);
    const { fpeState, fpeDispatch } = useContext(FPEContext);
    const [formObj, setFormObj] = useState<{[key: string]: any}>({});
    const [loading, setLoading] = useState(false);

    const valueChange = async (value: string, questionObj: FPEQuestionObj) => {

        const formSectionData = fpeState.overallAssessmentData;
        const questionObjId = `${questionObj.questionId}-${questionObj.roleId}`;

        if (!formSectionData) return;

        setLoading(true);

        if (!formObj[questionObjId]) {
            setFormObj({...formObj, [questionObjId]: value });
            await sendQuestionData(questionObj.link, value, value);
        } else if (formObj[questionObjId] !== value) {
                setFormObj({...formObj, [questionObjId]: value });
                await sendQuestionData(questionObj.link, value, value);
        }

        formSectionData.questions = formSectionData?.questions.map(q => {
            const qObjId = `${q.questionId}-${q.roleId}`;

            if (qObjId === questionObjId) {
                questionObj.response = value;

                if (!!value && questionObj.required && questionObj.error) delete questionObj.error;
            }

            return q;
        });

        await getFormData({ link: fpeState.fpeUserEvalData?.formUrl, token, form: fpeState.fpeUserEvalData?.formType as string }, fpeDispatch);

        setLoading(false);
    }  

    const richTextChange = (text: string, content: string, questionObj: FPEQuestionObj) => {
        const formSectionData = fpeState.overallAssessmentData;
        const questionObjId = `${questionObj.questionId}-${questionObj.roleId}`;

        if (!formSectionData) return;

            if (!formObj[questionObjId]) {
                setFormObj({...formObj, [questionObjId]: content });
                sendQuestionData(questionObj.link, text, !text ? '' : content);
            } else if (formObj[questionObjId] !== content) {
                    setFormObj({...formObj, [questionObjId]: content });
                    sendQuestionData(questionObj.link, text, !text ? '' : content);
            }

        formSectionData.questions = formSectionData?.questions.map(q => {
            const qObjId = `${q.questionId}-${q.roleId}`;
            
            if (qObjId === questionObjId) {
                q.response = !text ? null : content;
                if (!!text && q.required && q.error) delete q.error;
            }
            return q;
        });

        fpeDispatch({
            type: "SET_FPE_FORM_TEXT_SECTION",
            payload: {
                section: "overallAssessmentData",
                value: !text ? null : content,
                questionId: questionObj.questionId,
                roleId: questionObj.roleId
            }
        });
    }

    const renderQuestions = () => {
        if (!fpeState.overallAssessmentData?.questions) return null;

        return fpeState.overallAssessmentData?.questions.map(questionObj => {
            const questionObjId = `${questionObj.questionId}-${questionObj.roleId}`;

            if (questionObj.type === 'yes-no' || questionObj.type === 'satisfaction') {
                return (
                    <FormControl error={Boolean(questionObj.error)} fullWidth variant="outlined">
                        <FormLabel 
                            required={questionObj.required} 
                        >
                                { questionObj.text }
                                { questionObj.helpText && <InfoTooltip title={questionObj.helpText} />}
                        </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={questionObj.text}
                                id={questionObjId}
                                defaultValue={questionObj.response}
                                onChange={(x, value) => valueChange(value, questionObj)}
                            >
                                {questionObj.options && questionObj.options.map(opt => (<FormControlLabel value={opt.value} control={<Radio  disabled={!questionObj.editable} />} label={opt.label} />))}                     
                            </RadioGroup>
                    </FormControl>
                );
            }

                return (
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="dense">
                            <RichText
                                onChange={(text: string, content: string) => richTextChange(text, content, questionObj)}
                                id={questionObjId}
                                label={questionObj.text}
                                info={questionObj.helpText}
                                required={questionObj.required}
                                value={questionObj.response}
                                readOnly={!questionObj.editable || !questionObj.editable}
                                error={questionObj.error}
                            />
                        </FormControl>
                    </Grid>
                );
            
            
        });

    }

    return (
        <Accordion
            expanded={fpeState.sixthOpen}
            onChange={() => {
                fpeDispatch({
                    type: "SET_SECTION_OPEN",
                    payload: {
                        type: "sixthOpen",
                        status: !fpeState.sixthOpen
                    }
                });
            }}
        >
                    <AccordionSummary
                    sx={{
                        color: '#004AA0',
                        fontSize: '20px',
                        minHeight: '100px',
                        borderBottom: '1px solid #e9e9e9'
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography fontSize={20} variant="h5">Overall Assessment</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ position: 'relative' }}>
                        <Box sx={{ width: '100%', position: 'absolute', top: 0, left: 0 }}>
                                {loading && <LinearProgress  />}
                        </Box>
                        <Box
                        component="form"
                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                        noValidate
                        autoComplete="off"
                        >
                            <div>
                            
                            { renderQuestions() }

                            </div>
                            

                        </Box>
                    </AccordionDetails>
                </Accordion>
    )
}