import React, { useContext, useState } from 'react';
import { useNavigate, useParams  } from 'react-router-dom';

import { FPEContext } from '../../components/fpe/fpeContext';

import  { submitDelegationFormData } from '../../services/fpe-service';

import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ConfirmationModal(props: { username: string }) {
    const params = useParams();
    const navigate = useNavigate();

    const { fpeState, fpeDispatch } = useContext(FPEContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);

        fpeDispatch({
            type: "SET_OPEN_CONFIRMATION",
            payload: false
        });
    };

    const submit = async () => {
        setLoading(true);
        const res = await submitDelegationFormData(props.username, params.formId, params.year, params.form);

            if (res?.status === 200) {
                navigate(`/`);
                handleClose();
                setLoading(false);
            }
    }

    return (
      <div>

        <Modal
          open={fpeState.openConfimation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography variant="h5" marginBottom={4}>Confirm Delegation</Typography>
            <Typography fontWeight={500} marginBottom={5}>
              Note: You will not be able to edit the form after submission.
            </Typography>
            <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
                                    <Button 
                                        size="large"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button 
                      size="large"
                      onClick={submit}
                  >
                      Confirm
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: blue[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>            
                
            </ButtonGroup>
          </Box>
        </Modal>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                        Form has been submitted!
                                </Alert>
                            </Snackbar>
      </div>
    );
  }