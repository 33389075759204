import { styled } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";

import { EvaluationObj, FormLink } from '../../components/account/accountContext';

import Typography from '@mui/material/Typography';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import Styles from './home-list.module.scss';



export default function TableRows({ data } : { data: Array<EvaluationObj> }) {
    const navigate = useNavigate();

    const nameStyle = { display: 'inline', marginRight: '8px'}
    const titleStyle = { display: 'inline', marginRight: '8px', fontSize: '1.05rem' };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: '1px solid #e9e9e9',
        '& .MuiTableCell-root': {
          border: 'none'
        },
        /*'&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },*/
        '&:last-child': {
          borderBottom: 'none'
        },
        '&:first-child td, &:first-child th': {
          border: 0,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const sendToFPE = (formId: number, year: number, formType: string) => {
        const route = formType === 'FPE' ? '/fpe' : '/pr';

        navigate(`${route}/${formId}/${year}`);        
      }

      const showButtonMenu = (obj: EvaluationObj) => {

        return (
            <ButtonGroup variant="contained" aria-label="Basic button group" sx={{ float: 'right', borderRadius: 0, boxShadow: 'none'  }}>
                {
                    obj.links.map((linkObj: FormLink) => (
                        <Button sx={{ border: 'none' }} variant="outlined" onClick={() => sendToFPE(obj.formId, obj.year, linkObj.form)}>{`${linkObj.form} Access`}</Button>
                    ))
                }
            </ButtonGroup>
            
        );
      }
      
    return (
        <>

        {
            data.map(obj => (
                <StyledTableRow key={obj.name}>
                    <StyledTableCell component="th" scope="row">
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <div style={{ borderBottom: '1px solid #ececec', paddingBottom: '16px' }}>
                                <Grid container xs={12}>
                                    <Grid item xs={9}>
                                        <Typography sx={nameStyle} variant="h5" gutterBottom>
                                            { obj.name }                                       
                                        </Typography>
                                    </Grid>
                                    

                                    <Grid item xs={3}>
                                        { showButtonMenu(obj) }
                                    </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Degree:                                       
                                                </Typography>  
                                                <span className={Styles.description}>
                                                    { obj.degree }
                                                </span> 
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Department:                                       
                                                </Typography>    
                                                <span className={Styles.description}>
                                                    { obj.department }
                                                </span> 
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Division:                                       
                                                </Typography>    
                                                <span className={Styles.description}>
                                                    { obj.division }
                                                </span> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item md={4} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Rank:                                       
                                                </Typography> 
                                                <span className={Styles.description}>
                                                    { obj.rank }
                                                </span>  
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Tenure Status:                                       
                                                </Typography>  
                                                <span className={Styles.description}>
                                                    { obj.tenureStatus }
                                                </span>  
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Pay Category:                                       
                                                </Typography> 
                                                <span className={Styles.description}>
                                                    { obj.payCategory }
                                                </span>  
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Faculty Type:                                       
                                                </Typography>
                                                <span className={Styles.description}>
                                                    { obj.facultyType }
                                                </span> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>

                            <Grid item md={4} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Status:                                       
                                                </Typography>    
                                                { obj.status }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                Primary Evaluator:                                       
                                                </Typography>    
                                                { obj.primaryEvaluator }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                    Other Evaluator:                                       
                                                </Typography>    
                                                { obj.otherEvaluator }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                                Department Chair:                                       
                                                </Typography>    
                                                { obj.departmentChair }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledTableCell>
                </StyledTableRow>
            ))
        }
        </>
    );
}