import React from 'react';
import Axios from 'axios';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getBaseUrl } from './services/auth-service';


Axios.defaults.baseURL = getBaseUrl();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const THEME = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#434e53",
          
          ':not(h1, h2, h3, h4, h5, h6)': {
            fontSize: "14px",
            fontFamily: "PT Root UI, sans-serif",
          }
        },
        h1: {
          fontFamily: '"Qlassy", sans-serif',
          fontWeight: 600
        },
        h2: {
          fontFamily: '"Qlassy", sans-serif',
          fontWeight: 600
        },
        h3: {
          fontFamily: '"Qlassy", sans-serif',
          fontWeight: 600
        },
        h4: {
          fontFamily: '"Qlassy", sans-serif',
          fontWeight: 600
        },
        h5: {
          fontFamily: '"Qlassy", sans-serif',
          fontWeight: 600
        },
        h6: {
          fontFamily: '"Qlassy", sans-serif',
          fontWeight: 600
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000',
          margin: '20px 8px 10px 0', 
          fontSize: '.9rem', 
          fontWeight: 600,
        },
        disabled: {
          color: 'rgba(0, 0, 0, 0.6)'
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.6)',
          margin: 0,
        },
        disabled: {
          color: 'rgba(0, 0, 0, 0.6)'
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        disabled: {
          color: 'rgba(0, 0, 0, 0.6)'
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          paddingLeft: 10
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRight: '1px solid #e9e9e9',
          fontFamily: "PT Root UI, sans-serif",
          fontSize: '14px',
          textTransform: "capitalize",
        },
        selected: {
          color: '#000'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#ffbc00'
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          fontFamily: "PT Root UI, sans-serif",
          border: '1px solid #d7d7d7',
          color: '#000',
          backgroundColor: '#fbfbfb',
          //borderRadius: '20px',
          fontWeight: 600,
          fontSize: '14px',
          textTransform: 'capitalize'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "PT Root UI, sans-serif",
          border: '1px solid #d7d7d7',
          color: '#000',
          backgroundColor: '#fbfbfb',
          //borderRadius: '20px',
          fontWeight: 600,
          fontSize: '14px',
          textTransform: 'capitalize'
        }
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'red'
        }
      }
    }
  }
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
