import React, { useContext, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import RichText from '../../components/richTextEditor/rich-text-editor';

import Typography from '@mui/material/Typography';

import { FPEContext, FPEQuestionObj } from '../../components/fpe/fpeContext';

import { getOptOutFormData, sendQuestionData } from '../../services/opt-out-service'

export default function OptOutForm () {
    const params = useParams();
    const location = useLocation();
    const { fpeState, fpeDispatch } = useContext(FPEContext);

    const [loading, setLoading] = useState(true);
    const [formObj, setFormObj] = useState<{[key: string]: any}>({});

    useEffect(() => {
        const getData = async (formId: string | undefined, year: string | undefined, link?: string | undefined) => {
            setLoading(true);

            const res = await getOptOutFormData(formId, year, link);

            if (res?.status === 200) {

                fpeDispatch({
                    type: "SET_OPTOUT_FORM",
                    payload: res.data?.header
                });

                fpeDispatch({
                    type: "SET_OPTOUT_FORM_SECTIONS",
                    payload: res.data?.sections
                });

            }

            setLoading(false);
        }

        if (!fpeState.optOutForm) {

            if (location?.state?.formUrl) {
                getData(undefined, undefined, location?.state?.formUrl);
            } else if (params.formId && params.year) {
                getData(params.formId, params.year);
            }
        }
        
    }, [setLoading, fpeState.optOutForm, fpeDispatch]);

    const valueChange = () => {}

    const richTextChange = (text: string, content: string, questionObj: FPEQuestionObj) => {
        const formSectionData = fpeState.optOutForm;
        const formMainData = fpeState.optMainData;

        if (!formSectionData || !formMainData) return;

        if (formSectionData.formUrl) {

            if (!formObj[questionObj.formId]) {
                    setFormObj({...formObj, [questionObj.formId]: content });
                    sendQuestionData(questionObj.link, text, !text ? '' : content);
            } else if (formObj[questionObj.formId] !== content) {
                        setFormObj({...formObj, [questionObj.formId]: content });
                        sendQuestionData(questionObj.link, text, !text ? '' : content);
            }
            
            formMainData.questions = formMainData?.questions.map(q => {
                if (q.formId === questionObj.formId) {
                    questionObj.response = !text ? null : content;
                    if (!!text && questionObj.required && questionObj.error) delete questionObj.error;
                }
                return q;
            });

            fpeDispatch({
                type: "SET_FPE_FORM_SECTION",
                payload: {
                    section: "optMainData",
                    data: formSectionData
                }
            });
            
        }
    }
    
    const renderQuestions = () => {
        if (!fpeState.optMainData?.questions) return null;

        return fpeState.optMainData?.questions.map(questionObj => {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <RichText
                            onChange={(text: string, content: string) => richTextChange(text, content, questionObj)}
                            id={questionObj.questionId}
                            label={questionObj.text}
                            required={questionObj.required}
                            value={questionObj.response}
                            readOnly={!questionObj.editable}
                            error={questionObj.error}
                        />
                    </FormControl>
                </Grid>
            );
        });

    }
   

    return (
        <>
            {!fpeState.optOutForm && <p>loading .... </p>}
            <Typography variant="h4" gutterBottom>
                Opt-Out Form
            </Typography>
                <form>
                    <Box
                        component="form"
                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                        noValidate
                        autoComplete="off"
                        >
                        <Grid container spacing={[2, 2]} columnSpacing={[2, 2]}> 
                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={!!fpeState?.optOutForm?.name} 
                                            htmlFor="component-outlined"
                                        >
                                            Name
                                        </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            required
                                            label="Name"
                                            readOnly
                                            value={fpeState?.optOutForm?.name}
                                            notched={!!fpeState?.optOutForm?.name}
                                        />
                                    </FormControl>
                                </Grid>                           
                            
                            
                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={!!fpeState?.optOutForm?.degree}
                                            htmlFor="component-outlined">
                                                Degree
                                            </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Degree"
                                            readOnly
                                            value={fpeState?.optOutForm?.degree}
                                            notched={!!fpeState?.optOutForm?.degree}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            shrink={!!fpeState?.optOutForm?.payCategory}
                                            htmlFor="component-outlined">
                                                Pay Category
                                        </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Pay Category"
                                            readOnly
                                            value={fpeState?.optOutForm?.payCategory}
                                            notched={!!fpeState?.optOutForm?.payCategory}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.optOutForm?.facultyType}>
                                                Faculty Type
                                        </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Faculty Type"
                                            readOnly
                                            value={fpeState?.optOutForm?.facultyType}
                                            notched={!!fpeState?.optOutForm?.facultyType}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.optOutForm?.rank}>
                                            Academic Rank
                                        </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Academic Rank"
                                            readOnly
                                            value={fpeState?.optOutForm?.rank}
                                            notched={!!fpeState?.optOutForm?.rank}
                                        />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                        shrink={!!fpeState?.optOutForm?.department}
                                        htmlFor="component-outlined">
                                            Department
                                        </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Department"
                                            readOnly
                                            value={fpeState?.optOutForm?.department}
                                            notched={!!fpeState?.optOutForm?.department}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={!!fpeState?.optOutForm?.tenureStatus}
                                            htmlFor="component-outlined">
                                            Tenure Status
                                            </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Tenure Status"
                                            readOnly
                                            value={fpeState?.optOutForm?.tenureStatus}
                                            notched={!!fpeState?.optOutForm?.tenureStatus}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.optOutForm?.primaryEvaluator}>
                                                Primary Evaluator
                                            </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Primary Evaluator"
                                            value={fpeState?.optOutForm?.primaryEvaluator}
                                            notched={!!fpeState?.optOutForm?.primaryEvaluator}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            shrink={true}
                                            htmlFor="component-outlined">
                                                Secondary Evaluator
                                        </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Secondary Evaluator"
                                            readOnly
                                            value={fpeState?.optOutForm?.otherEvaluator || "N/A"}
                                            notched={true}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel 
                                            htmlFor="component-outlined"
                                            shrink={!!fpeState?.optOutForm?.departmentChair}>
                                                Chair / Final Evaluator
                                            </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            label="Chair / Final Evaluator"
                                            readOnly
                                            value={fpeState?.optOutForm?.departmentChair}
                                            notched={!!fpeState?.optOutForm?.departmentChair}
                                        />
                                    </FormControl>
                                </Grid>

                                { renderQuestions() }

                                
                            </Grid>
                    </Box>

                </form>            
        </>
    );
}