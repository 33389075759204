import { createContext, useReducer } from 'react';
import FPEReducer from "./fpeReducer";

export type OptOutFormObj = {
    degree: string;
    department: string;
    departmentChair: string;
    facultyType: string;
    formId: number;
    formUrl: string;
    name: string;
    otherEvaluator: string;
    payCategory: string;
    primaryEvaluator: string;
    rank: string;
    status: string;
    tenureStatus: string;
}

export type FPEUserEvalFormObj = {
    degree: string;
    department: string;
    division: string;
    departmentChair: string;
    facultyType: string;
    formId: number;
    formType: string;
    formUrl: string;
    name: string;
    otherEvaluator: string;
    payCategory: string;
    primaryEvaluator: string;
    rank: string;
    status: string;
    tenureStatus: string;
}

export type SelectOptionObj = {
    value: string,
    label: string
}

export type ErrorFieldObj = {
    type: string,
    message: string
}

export type FPEQuestionObj = {
    formId: number,
    disabled?: boolean;
    questionId: string,
    editable: boolean,
    required: boolean,
    requiredIf: string,
    text: string,
    type: string,
    helpText: string | null,
    options: SelectOptionObj[] | null,
    response: null | string | boolean,
    link: string,
    error?: ErrorFieldObj,
    roleId: string,
    respondedBy: string,
    respondedOn: string
}

export type FileObj = {
    filename: string;
    downloadUrl: string;
    deleteUrl: string;
}

export type FPEFormSectionObj = {
    id: string,
    title: string | null,
    files: FileObj[],
    questions: FPEQuestionObj[],
    uploads: string;
    canAttach?: boolean;
    attachable?: boolean;
}

export type FPESubmitOptionsObj = {
    label: string;
    link: string;
    validate: boolean;
}

export type InitialState = {
    fpeUserEvalData?: FPEUserEvalFormObj,
    upsomUppFpeData?: FPEFormSectionObj,
    researchScholarData?: FPEFormSectionObj,
    teachingData?: FPEFormSectionObj,
    clinicalData?: FPEFormSectionObj,
    serviceAdminData?: FPEFormSectionObj,
    overallAssessmentData?: FPEFormSectionObj,
    signaturesData?: FPEFormSectionObj,
    optOutForm?: OptOutFormObj,
    optMainData?: FPEFormSectionObj,
    uploads?: {},
    firstOpen: boolean,
    secondOpen: boolean,
    thirdOpen: boolean,
    fourthOpen: boolean,
    fifthOpen: boolean,
    sixthOpen: boolean,
    allOpen: boolean,
    openConfimation: boolean,
    formType?: string;
    formStatus?: string,
    formId?: number,
    canDelegate?: boolean;
    title?: string;
    link?: string;
    submitOptions?: FPESubmitOptionsObj[] | []
    chosenSubmitLink?: string;
    errors: {[x: string]: string}
}

export const initialState: InitialState = {
    fpeUserEvalData: undefined,
    upsomUppFpeData: undefined,
    researchScholarData: undefined,
    teachingData: undefined,
    clinicalData: undefined,
    serviceAdminData: undefined,
    overallAssessmentData: undefined,
    signaturesData: undefined,
    optOutForm: undefined,
    optMainData: undefined,
    uploads: {},
    firstOpen: false,
    secondOpen: false,
    thirdOpen: false,
    fourthOpen: false,
    fifthOpen: false,
    sixthOpen: false,
    allOpen: false,
    openConfimation: false,
    formStatus: undefined,
    errors: {}
}

export type FPEContextType = {
    fpeState: InitialState,
    fpeDispatch: React.Dispatch<any>
}



export const FPEContext = createContext<FPEContextType>({ fpeState: initialState, fpeDispatch: () => null });

export const FPEProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(FPEReducer, initialState);

    return (
        <FPEContext.Provider 
            value={{
                fpeState: state,
                fpeDispatch: dispatch
            }}
        >
        {children}
        </FPEContext.Provider>
    );
}