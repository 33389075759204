import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams  } from 'react-router-dom';

import { getHelpFileList } from '../../services/general';

import FileList from '../../components/file/fileList';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import EvaluationFormTimeLineImage from '../../assets/images/Evaluation_Form_Timeline_For_Team.png';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';

export default function Help() {
    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [helpFileList, setHelpFileList] = useState<Array<any> | undefined>(undefined);


    useEffect(() => {

        const getList = async () => {
            const res = await getHelpFileList();

            if (res?.status === 200) {
                setHelpFileList(res.data);
            }
            setLoading(false);
        }

        if (!helpFileList) {
            setLoading(true);
            getList();
        }

    }, []);

    return (
        <>
            <Box marginBottom={4} borderBottom="1px solid #e9e9e9" paddingBottom={1}>
                <Typography variant="h6" gutterBottom>
                Help
                </Typography>
            </Box>

          {loading && 
            <CircularProgress
                size={24}
                sx={{
                    color: blue[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
          }

            <Box marginBottom={4} borderBottom="1px solid #e9e9e9" paddingBottom={1}>
                <Typography variant="h6" gutterBottom>
                Evaluation Form Timeline
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: { xs: 350, md: 1050 },
                        }}
                        alt="Evalution form timeline for team process"
                        src={EvaluationFormTimeLineImage}
                    />
                </Grid>
            </Grid>

          {!loading && helpFileList && 
            <Grid container>
                <Grid item xs={12}>
                    <FileList 
                        files={helpFileList} 
                        maxWidth={800}
                    />
                </Grid>
            </Grid>
          }
        </>
    );
}