import React, { useEffect, useState, useRef } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import InfoTooltip from '../../components/infoTooltip/infoTooltip';
import { styled } from '@mui/material/styles';

import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');

import 'quill/dist/quill.snow.css'; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

import Styles from './rich-text-editor.module.scss';

export default function RichText (props: any) {

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }]
    ],
  };

  const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
    // 'image'
    // 'video'
  ];

  const labelStyles = { 
    color: '#000', 
    display: 'inline', 
    margin: '20px 8px 10px 0', 
    fontSize: '.9rem', 
    fontWeight: 600,

  };

  const errorStyles = props.error ? { border: '1px solid red', borderRadius: '4px'} : {};
  const errorLabelStyle = props.error ? { color: 'red' } : {};

  const { quill, quillRef } = useQuill({ modules, formats });
  const [initialLoad, setInitialLoad] = useState(false);
  let timeoutSubmit = useRef<any>(undefined);

  useEffect(() => {

    if (quill) {

      if (!initialLoad) {
        const toolbarEls = Array.from(document.querySelectorAll<HTMLElement>(".ql-toolbar"));
        const borderStyle = '1px solid #e9e9e9';


        if (quillRef && toolbarEls) {
          quillRef.current.style.border = borderStyle;
          quillRef.current.style.borderTop = 'none';
          quillRef.current.style.borderRadius = '0 0px 5px 5px';

          toolbarEls.forEach(el => {
            el.style.border = borderStyle;
            el.style.borderRadius = '5px 5px 0 0';
          });

          const editorDivEls = document.getElementsByClassName('ql-editor') as HTMLCollectionOf<HTMLElement>;

          for (var i = 0; i < editorDivEls.length; i++) {
            editorDivEls[i].style.width = 'calc(100vw - 340px)';

          }
        }

        if (props.value) {
          quill.setContents(quill.clipboard.convert(props.value), 'silent');
        }

        if (props.readOnly) quill.enable(false);

      }

      setInitialLoad(true);
    }
    
  }, [quill, initialLoad, props.value]);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
        //console.log('Text change!');

        const text = quill.getText();
        const contents = quill.getContents();

        //console.log(quill.getText()); // Get text only
        //console.log(quill.getContents()); // Get delta contents
        /*console.log(quill.root.innerHTML); // Get innerHTML using quill
        console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        */
       
        if (!props.readOnly) {
          clearTimeout(timeoutSubmit.current);
          const text = quill.getText();
          //console.log('text - ', text);

          timeoutSubmit.current = setTimeout(() => {
            //console.log('the change - ', text.trim());
            props.onChange(text.trim(), quill.root.innerHTML);
          }, 500);
        
        }
      });

      if (!props.readOnly) {
        quill.on('selection-change', (delta: any, oldDelta: any, source: any) => {
          //console.log('selection-change');
          if (delta === null && oldDelta !== null) {
            const text = quill.getText();
            
            props.onChange(text.trim(), quill.root.innerHTML);
          }
        });
      }
    }
  }, [quill]);

  const stylesObj = props.styles;

  //console.log(quill);    // undefined > Quill Object
  // console.log('quillRef -', quillRef); // { current: undefined } > { current: Quill Editor Reference }

  return (
    <>
          {props.label && 
            <label style={{...labelStyles, ...errorLabelStyle}}>
              {props.label} 
              {props.info && 
                <InfoTooltip title={props.info} />
              }
              {props.required ? 
                <span className={props.error ? Styles.astriskError : ''}>*</span> : 
                ''
              }
            </label>
          }

          <div style={{...stylesObj, ...errorStyles}} className={Styles.richTextEditor}>
            <div ref={quillRef} />
          </div>
    </>
  );
};