import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../components/auth/authContext';
import { getFormData } from '../../components/fpe/fpeAction';
import { FPEContext, FPEQuestionObj, FPEFormSectionObj, FPESubmitOptionsObj } from '../../components/fpe/fpeContext';
import { sendQuestionData } from '../../services/opt-out-service';
import FPESubmitButton from './fpe-submit-button';

import Box from '@mui/material/Box';

import { FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Typography from '@mui/material/Typography';
import InfoTooltip from '../../components/infoTooltip/infoTooltip';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function SignaturesForm () {

    const { token, userType } = useContext(AuthContext);
    const { fpeState, fpeDispatch } = useContext(FPEContext);
    const [formObj, setFormObj] = useState<{[key: string]: any}>({});

    const [open, setOpen] = useState(false);
    const [errorTandC, setErrorTandC] = useState(false);
    const [errorMessages, setErrorMessages] = React.useState<string[]>([]);

    useEffect(() => {

        setOpen(!errorMessages.length ? false : true);

    }, [errorMessages]);

    useEffect(() => {

        if (!Object.keys(formObj).length) {
            const newFormObj: {[x: string]: any} = {};

            fpeState.signaturesData?.questions.forEach((question, index) => {
                if (question.type === 'signature' && question.required) {
                    newFormObj[`${question.questionId}${index}`] = question.response ? question.response : '';
                }
            });
            setFormObj(newFormObj);
        }

    }, [fpeState.signaturesData]);

    const valueChange = async (e: React.ChangeEvent<HTMLInputElement>, questionObj: FPEQuestionObj, questionId: string) => {
        const formSectionData = {...fpeState.signaturesData} as FPEFormSectionObj | undefined;
        const selectTypeEl = ['radio', 'checkbox'];

        let value = e.target.value;

        if (e.target.type === 'checkbox') {
            value = e.target.checked ? 'accept' : 'decline';
        }

        if (!formSectionData) return;

        if (!formObj[questionId]) {
            setFormObj({...formObj, [questionId]: value });
            await sendQuestionData(questionObj.link, value, value);
        } else if (formObj[questionId] !== value) {
                setFormObj({...formObj, [questionId]: value });
                await sendQuestionData(questionObj.link, value, value);
        }

        if (selectTypeEl.indexOf(e.target.type) > -1) {
            await getFormData({ link: fpeState.fpeUserEvalData?.formUrl, token, form: fpeState.fpeUserEvalData?.formType as string }, fpeDispatch);
        }

    }

    const signatureValueChange = async (e: React.ChangeEvent<HTMLInputElement>, questionObj: FPEQuestionObj, questionId: string) => {
        const formSectionData = {...fpeState.signaturesData} as FPEFormSectionObj | undefined;
        let value = e.target.value;

        if (e.target.type === 'checkbox') {
            value = e.target.checked ? 'accept' : 'decline';
        }

        if (!formSectionData) return;

        if (!formObj[questionId]) {
            setFormObj({...formObj, [questionId]: value });
            await sendQuestionData(questionObj.link, value, value);
        } else if (formObj[questionId] !== value) {
                setFormObj({...formObj, [questionId]: value });
                await sendQuestionData(questionObj.link, value, value);
        }

        await getFormData({ link: fpeState.fpeUserEvalData?.formUrl, token, form: fpeState.fpeUserEvalData?.formType as string }, fpeDispatch);
    }

    const renderDateSignedEl = () => {
        return (
            <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker label="Date Signed" />
                </LocalizationProvider>
            </FormControl>
        )
    }

    const verifySubmittion = (submitOption?: FPESubmitOptionsObj) => {
        const errors = submitOption?.validate ? validationErrors() : [];
        setErrorMessages(errors);

        if (!errors.length) {
            fpeDispatch({
                type: "SET_SUBMIT_LINK",
                payload: submitOption?.link
            });

            fpeDispatch({
                type: "SET_OPEN_CONFIRMATION",
                payload: true
            });
        }

    }

    const openSection = (section: string) => {
        fpeDispatch({
            type: "SET_SECTION_OPEN",
            payload: {
                type: section,
                status: true
            }
        });
    }

    const getFPEQuestionObj = (id: string): FPEQuestionObj | undefined => {

        const questionBatch = [
            ...fpeState.upsomUppFpeData ? fpeState.upsomUppFpeData.questions : [],
            ...fpeState.researchScholarData ? fpeState.researchScholarData.questions : [],
            ...fpeState.teachingData ? fpeState.teachingData.questions : [],
            ...fpeState.clinicalData ? fpeState.clinicalData.questions : [],
            ...fpeState.serviceAdminData ? fpeState.serviceAdminData.questions : [],
            ...fpeState.overallAssessmentData ? fpeState.overallAssessmentData.questions : [],
            ...fpeState.signaturesData ? fpeState.signaturesData.questions : []
        ]

        return questionBatch.find(q => q.questionId === id);

    }

    const validationErrors = () => {
        const errors: string[] = [];
        const requiredFieldsMessage = "required fields are missing";

        const validateRequiredQuestions = (questionObj: FPEQuestionObj, index: number, section?: string) => {

            // required-if check
            const requiredIfQuestionId = questionObj.requiredIf;

            const requiredQuestionObj = getFPEQuestionObj(requiredIfQuestionId);

                if ((requiredQuestionObj?.response === 'yes' || questionObj.required) && (questionObj.response === null || !questionObj.response)) {
                    questionObj.error = {
                        type: 'required',
                        message: 'Missing answer for required question / field'
                    }

                    if (errors.indexOf(requiredFieldsMessage) === -1) {
                        errors.push(requiredFieldsMessage);
                    }

                    if (section) openSection(section);
                } else {
                    delete questionObj.error;
                }

            return questionObj;
        }

        const validateFPEFormSection = (formData: FPEFormSectionObj, section?: string) => {
            formData.questions.map((questionObj: FPEQuestionObj, index: number) => validateRequiredQuestions(questionObj, index, section));
        }

        if (fpeState.upsomUppFpeData) validateFPEFormSection(fpeState.upsomUppFpeData, "firstOpen");
        if (fpeState.researchScholarData) validateFPEFormSection(fpeState.researchScholarData, "secondOpen");
        if (fpeState.teachingData) validateFPEFormSection(fpeState.teachingData, "thirdOpen");
        if (fpeState.clinicalData) validateFPEFormSection(fpeState.clinicalData, "fourthOpen");
        if (fpeState.serviceAdminData) validateFPEFormSection(fpeState.serviceAdminData, "fifthOpen");
        if (fpeState.overallAssessmentData) validateFPEFormSection(fpeState.overallAssessmentData, "sixthOpen");
        if (fpeState.signaturesData) validateFPEFormSection(fpeState.signaturesData);

        if (fpeState.signaturesData) {
            const termsQuestionObj = fpeState.signaturesData.questions.find(obj => obj.questionId === 'fpe-terms');

            if (termsQuestionObj?.response !== 'accept') {
                errors.push("Terms and Conditions has not been selected!");
                setErrorTandC(true);
            } else {
                setErrorTandC(false);
            }
        }

        return errors;
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpen(false);
    };

    const renderQuestions = () => {
        if (!fpeState.signaturesData?.questions) return null;

        return fpeState.signaturesData?.questions.map(questionObj => {
            const questionObjId = `${questionObj.questionId}-${questionObj.roleId}`;
            const questionEl = (
                <FormControl margin="dense" error={Boolean(questionObj.error)} fullWidth variant="outlined">
                    <FormLabel
                        required={questionObj.required}
                    >
                        { questionObj.text }
                        { questionObj.helpText && <InfoTooltip title={questionObj.helpText} />}
                    </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name={questionObj.text}
                                    id={questionObjId}
                                    defaultValue={questionObj.response}
                                    onChange={(x) => {
                                        valueChange(x, questionObj, questionObjId)
                                    }}
                                >
                                    {questionObj.options && questionObj.options.map(opt => (<FormControlLabel value={opt.value} control={<Radio disabled={!questionObj.editable}/>} label={opt.label} />))}

                                </RadioGroup>
                </FormControl>
            );

            return (questionObj.type === 'yes-no') ? questionEl : null;

        });

    }

    const renderSignatures = () => {
        if (!fpeState.signaturesData?.questions) return null;

        return (
            <Grid container>
                <Grid item sm={12}>
                <Typography variant="h5" margin={'20px 0 20px 20px'}>Signatures</Typography>
                <hr />
                </Grid>
                { renderQuestions() }

            {
                fpeState.signaturesData?.questions.map((questionObj, index) => {
                    const type = questionObj.type;
                    //const duplicateQuestionIds = fpeState.signaturesData?.questions.filter((obj: FPEQuestionObj) => obj.questionId === questionObj.questionId);

                    if (type === 'terms') {
                        const termMarkup = () => {
                            return {
                                __html: questionObj.text
                            }
                        }
                        return (
                            <>
                            <div className='Terms-box'>
                                <div dangerouslySetInnerHTML={termMarkup()} style={{ width: '100%' }} />

                                {((fpeState.formStatus && ["PR-SELF", "FPE-SELF"].indexOf(fpeState.formStatus) > -1) || (fpeState.submitOptions && fpeState.submitOptions.length > 0)) && <FormGroup>
                                    <FormControl error={errorTandC}>
                                        <FormControlLabel
                                            required
                                            control={
                                                <>
                                                    <Checkbox
                                                        checked={questionObj.response === 'accept'}
                                                        onChange={(x) => valueChange(x, questionObj, questionObj.questionId)}

                                                        required={questionObj.required}
                                                        disabled={!questionObj.editable && questionObj.response === 'accept'}
                                                    />
                                                </>
                                            }
                                            label="I agree with the terms and conditions."
                                        />
                                        </FormControl>
                                    </FormGroup>
                                }
                            </div>
                            </>
                        );
                    }

                    if (type === 'signature') {
                        const questionId = `${questionObj.questionId}${index}`;

                        return (
                            <Grid item sm={12}>
                            <FormControl error={Boolean(questionObj.error)} sx={{ width: '50ch' }} variant="outlined" margin="dense">
                                <TextField
                                    id="outlined-basic"
                                    label={questionObj.text}
                                    variant="outlined"
                                    onChange={(x) => signatureValueChange(x as React.ChangeEvent<HTMLInputElement>, questionObj, questionId)}
                                    required={questionObj.required}
                                    disabled={!questionObj.editable}
                                    defaultValue={questionObj.response}
                                    value={formObj[questionId]}
                                />
                            </FormControl>
                            </Grid>

                        );
                    }
                })
            }
            </Grid>
        );
    }

    const signatureSigned = () => {
        const requiredSignatures = fpeState.signaturesData?.questions.filter((question, index) => {
            const formStateKey = Object.keys(formObj);
            const matchingFormStateKey = formStateKey.find(key => key === `${question.questionId}${index}`);
            const emptyFormStateVal = matchingFormStateKey && !formObj[matchingFormStateKey].trim();

            if (question.type === 'signature' && question.required) {

                if (matchingFormStateKey) {
                    if (!emptyFormStateVal) {
                        return false;
                    } else if (emptyFormStateVal && question.response !== null) {
                        return true;
                    }

                    return true;
                } else if (question.response === null || !question.response) {
                    return true;
                }
            }

            return false;
        });

        return Boolean(!requiredSignatures?.length);
    }

    return (
        <>

                        <Box
                        component="form"
                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                        noValidate
                        autoComplete="off"
                        >
                            { renderSignatures() }
                            <FPESubmitButton submit={verifySubmittion} signaturesSigned={signatureSigned()} />

                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                        {errorMessages.map(e => (<p>{e}</p>))}
                                </Alert>
                            </Snackbar>

                        </Box>
        </>
    );
}