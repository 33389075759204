import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../components/auth/authContext';

import InitializationList from './initialization-list';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function Initialization() {
    const { todoData } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
      if (todoData && !todoData.initialization) {
        navigate(`/`);        
      }
    }, [todoData]);

    return (
        <>
          <Box>
            <InitializationList />
          </Box>
        </>
    )
}