import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getFormData } from '../../components/fpe/fpeAction';
import { FPEContext } from '../../components/fpe/fpeContext';
import { AuthContext } from '../../components/auth/authContext';

import { ButtonGroup, Button, Grid, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Chip from '@mui/material/Chip';

import UPSOMFPEForm from './upsom-upp-fpe';
import ResearchAndScholarForm from './research-scholar-form';
import TeachingActivitiesForm from './teaching-activites-form';
import ClinicalActivitiesForm from './clinical-activites-form';
import ServiceAdminDEIForm from './service-admin-dei-form';
import OverallAssessmentForm from './overall-assessment-form';
import SignaturesForm from './signatures-form';
import ConfirmationModal from './confirmation-modal';

export default function PRForm () {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { fpeState, fpeDispatch } = useContext(FPEContext);
    const { token } = useContext(AuthContext);

    useEffect(() => {

        if (location?.state?.formUrl) {
            getFormData({ link: location?.state?.formUrl, token, form: "PR" }, fpeDispatch);
        } else if (params.formId && params.year) {
            getFormData({ formId: params.formId, year: params.year, token, form: "PR" }, fpeDispatch);
        }

    }, []);

    const printForm = () => {
        fpeDispatch({
            type: "SET_ALL_OPEN",
            payload: true
        });

        setTimeout(() => window.print(), 500);
    }

    return (
        <>
                    <Box marginBottom={4} borderBottom="1px solid #e9e9e9" paddingBottom={1}>
                        <Typography variant="h6" gutterBottom>
                        {fpeState?.title}
                        </Typography>
                    </Box>
                    <Grid container>
                        <Grid xs={6} item>
                        {fpeState?.fpeUserEvalData?.status && 
                            <Chip 
                                size="medium" 
                                sx={{ marginTop: '6px', padding: '10px 16px' }}
                                icon={<AssignmentIcon />}
                                label={(<strong>{fpeState?.fpeUserEvalData?.status}</strong>)} />
                        }
                        </Grid>
                        <Grid xs={6} item>
                        <div style={{ float: 'right', marginBottom: '10px'}}>
                        <ButtonGroup>
                        <Button 
                        sx={{
                            '@media print': {
                                display: 'none'
                              }
                        }}
                        startIcon={fpeState.allOpen ? <RemoveIcon /> : <AddIcon />} 
                        variant="outlined" 
                        size="large"
                        onClick={() => { 
                            fpeDispatch({
                                type: "SET_ALL_OPEN",
                                payload: !fpeState.allOpen
                            });
                        }}
                        >
                            { `${ fpeState.allOpen ? "Close" : "Open" } All Sections` }
                        </Button>
                        {fpeState.canDelegate && 
                            <Button
                            variant="outlined" 
                            size="large"
                            onClick={() => { 
                                navigate(`/delegate/${params.formId}/${params.year}/PR`);        
                            }}
                            >
                                Send To Delegate
                            </Button>
                        }

                        <Button
                            startIcon={<PrintIcon />}
                            variant="outlined" 
                            size="large"
                            onClick={printForm}
                            >
                                Print
                            </Button>
                        
                        </ButtonGroup>
                    </div>
                        </Grid>
                    </Grid>

                    <UPSOMFPEForm />
                    <ResearchAndScholarForm />
                    <TeachingActivitiesForm />
                    <ClinicalActivitiesForm />
                    <ServiceAdminDEIForm />
                    {fpeState.overallAssessmentData && <OverallAssessmentForm />}
                    <SignaturesForm />
                    <ConfirmationModal />
        </>
    );
}