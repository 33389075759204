import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../components/auth/authContext';
import { getTodoData, getTodoInitialization } from '../../services/todo-service';

import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import Styles from './home-list.module.scss';
import { Box } from '@mui/material';

type InitializationObj = {
  orgUnit: string;
  count: number;
  link: string;
}

export default function InitializationList() {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Array<InitializationObj> | undefined>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);

    useEffect(() => {

        const getData = async () => {

            const res = await getTodoInitialization(token as string);

            const resTodo = await getTodoData();

            if (res?.status === 200) {
                setData(res.data.forms);
                setTitle(res.data.title);
            }
        }

        if (token !== null && !data) {
          setLoading(true);
          getData();
          setLoading(false);

        }
    }, [setLoading, setData, token, data]);

    const titleStyle = { display: 'inline', marginRight: '8px', fontSize: '1.05rem' };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: '1px solid #e9e9e9',
        '& .MuiTableCell-root': {
          border: 'none'
        },
        /*'&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },*/
        '&:last-child': {
          borderBottom: 'none'
        },
        '&:first-child td, &:first-child th': {
          border: 0,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      


      const sendToFPE = (link: string) => {
        window.location.href = link;
      }


    return (
      <React.Fragment>
        <Box marginBottom={4} borderBottom="1px solid #e9e9e9" paddingBottom={1}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Box>
        {loading && <p>loading .....</p>}
        {!loading && data &&
        <TableContainer>
            <Table aria-label="customized table">
                <TableBody>
                {data.map((obj) => (
                    <StyledTableRow key={obj.orgUnit}>
                        <StyledTableCell component="th" scope="row">
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}>
                                          <Badge badgeContent={obj.count} color="primary">
                                            <BusinessIcon color="action" />
                                          </Badge>
                                        </Grid>
                                        <Grid item xs={11}>
                                        <Typography sx={titleStyle} fontWeight={500} gutterBottom>
                                              Organization Unit:                                       
                                          </Typography>    
                                          <span className={Styles.description}>
                                            { obj.orgUnit }
                                          </span> 
                                        </Grid>
                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                  <Button variant="outlined" onClick={() => sendToFPE(obj.link)}>Access FPE</Button>
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        }
    </React.Fragment>
    );
}