import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export function AppNav(props: { items: Array<{ label: string, icon: ReactJSXElement, link?: string, func?: () => void }> }) {

    const menuItems = props.items;

    return (
        <List>
            {menuItems.map((item, index) => {
              const properties: { key: string, disablePadding: boolean; to?: string; component?: any; onClick?: () => void } = {
                key: item.label,
                disablePadding: true
              };

        
              if (item.link) {
                properties.to = item.link;
                properties.component = Link;
              } 
              
              if (item.func) {
                properties.onClick = () => {
                  if (item.func) item.func();
                };
              }

              return (
              <ListItem 
                {...properties} 
                >
                <ListItemButton>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primaryTypographyProps={{
                      fontWeight: 500
                    }}
                    primary={item.label} 
                  />
                </ListItemButton>
              </ListItem>
              )
            }
          )}
          </List>
    )

}