import Axios from 'axios';
import { getBaseUrl } from '../services/auth-service';

type Methods = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";

function getErrorMessage(error: unknown) {
    if (error instanceof Error) return error.message
    return String(error)
}

const reportError = ({message}: {message: string}) => {
    // send the error to our logging service...
  }

const handleError = (e: any) => {

    console.log('the error -> ', e);

    if (e?.response?.status){
        switch (e.response.status) {
            case 401:
            console.log('send to log in - ', getBaseUrl() + '/login/creds/form');
            localStorage.clear();
            window.location.href = getBaseUrl() + '/login/creds/form';
        }
    }
}
  
export const callApi = async (resource: string, type: Methods, payload?: any, options?: any): Promise<{status: number, data: any} | undefined> => {
    try {
        const res = await Axios[type](resource, payload, options);

        return {
            status: res.status,
            data: res.data
        };

    } catch(e) {
        handleError(e);
        //reportError({message: getErrorMessage(e)})
    }

}

export const getHelpFileList = async () => await callApi(`/attach/help/list/`, 'get');