import { callApi } from './general';

export const getTodoData = async () => await callApi('/todo', 'get');

export const getTodoInitialization = async (token: string) => await callApi('/todo/initialization', 'get', { headers: { 'Authorization': token } });
export const getTodoEvaluation = async (token: string) => await callApi('/todo/evaluation', 'get', { headers: { 'Authorization': token } });
export const getTodoNowEvaluations = async (token: string, queryParam?: string) => {
    let url = '/todo/evaluation/now';

    if (queryParam) url = `${url}?${queryParam}`; 

    return await callApi(url, 'get', { headers: { 'Authorization': token } });
}
export const getTodoCurrentYearEvaluations = async (token: string, queryParam?: string) => {
    let url = 'todo/evaluation/year';

    if (queryParam) url = `${url}?${queryParam}`;

    return await callApi(url, 'get', { headers: { 'Authorization': token } });
}

export const getTodoAllEvaluations = async (token: string, queryParam?: string) => {
    let url = '/todo/evaluation/all';

    if (queryParam) url = `${url}?${queryParam}`;

    return await callApi(url, 'get', { headers: { 'Authorization': token } });
}
export const getTodoOptOut = async () => await callApi('/todo/optout', 'get');

export const getFacultyList = async (url: string, queryParam: string) => await callApi(`${url}?q=${queryParam}`, 'get');

export const getDepartmentList = async (url: string, queryParam: string) => await callApi(`${url}?q=${queryParam}`, 'get');

export const getDivisionList = async (url: string, queryParam: string) => await callApi(`${url}?q=${queryParam}`, 'get');