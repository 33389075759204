import { callApi } from './general';

export const getOptOutFormData = async (formId: string | undefined, year: string | undefined, url: string | undefined) => {
    if (url) {
        return await callApi(url, 'get');
    }

    return await callApi(`/form/${formId}/${year}/OPT`, 'get');
}

export const sendQuestionData = async (questionLink: string, text: string | boolean, content: string | boolean) => await callApi(questionLink, 'post', { text: text, html: content});