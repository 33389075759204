import Root from "../views/root/root"
import HomeList from "../views/home-list/home-list"
import Initialization from "../views/home-list/initialization"
import FPEForm from "../views/fpe-form/fpe-form"
import PRForm from "../views/fpe-form/pr-form";
import OptOutForm from "../views/opt-out-form/opt-out-form"
import Delegate from "../views/delegate/delegate"
import Help from "../views/help/help"

import { FPEProvider } from '../components/fpe/fpeContext';


const Routes = [
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: '/',
                element: <HomeList />
            },
            {
                path: '/initialization',
                element: <Initialization />
            },
            {
                path: '/help',
                element: <Help />
            },
            {
                path: '/fpe/:formId/:year',
                element: <FPEProvider><FPEForm /></FPEProvider>
            },
            {
                path: '/pr/:formId/:year',
                element: <FPEProvider><PRForm /></FPEProvider>
            },
            {
                path: '/opt-out/:formId/:year',
                element: <FPEProvider><OptOutForm /></FPEProvider>
            },
            {
                path: '/delegate/:formId/:year/:form',
                element: <FPEProvider><Delegate /></FPEProvider>
            },
        ]
      }
]

export default Routes
