import React from 'react';

const accountReducer = (state: {}, action: { type: any, payload: any}) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                points: action.payload
            }
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "SET_EVAL_LIST_DATA":
            return {
                ...state,
                evaluationListData: action.payload
            }
        case "SET_OPTOUT_LIST_DATA":
            return {
                ...state,
                outOutListData: action.payload
            }
        case "SET_TITLE":
            return {
                ...state,
                title: action.payload
            }
        default:
            return state;
    }
}

export default accountReducer;