import { callApi } from './general';

export const getUserToken = async () => await callApi('/login/jwt', 'get', { withCredentials: true });

export const getBaseUrl = () => {
    const urlOrigin = String(window.location.host).toLowerCase();
    console.log('THE ORIGIN -- ', urlOrigin);
    
    if (urlOrigin.includes("evaluation.hs.pitt.edu")) {
        return "https://fpe.hs.pitt.edu";
    } else if (urlOrigin.includes("evaluationtest.hs.pitt.edu")) {
        return "https://fpetest.hs.pitt.edu";
    } else if (urlOrigin.includes("evaluationdev.hs.pitt.edu")) {
        return "https://volcanodev.hs.pitt.edu:9009";
    } else if (urlOrigin.includes("localhost")) {
        return "http://localhost:8009";
    }

    return "https://fpetest.hs.pitt.edu";        
    
  } 