import React, { useEffect, useState, useRef } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function InfoTooltip ({ title }: { title: string }) {

    return (
        <Tooltip title={title}>
            <IconButton>
                <InfoIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}