import FileSaver from 'file-saver';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import FileCopy from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';


export default function FileList (props: any) {

    const deleteFile = async (fileObj: any) => {

        console.log('file to remove - ', fileObj);

        await props.delete(fileObj.deleteUrl);

        if (props.callback) props.callback();
    }

    const downloadFile = async (fileObj: any) => {

        const res = await props.download(fileObj.downloadUrl);
        const excelBuffer = res.data;

        if (excelBuffer) {
            const data = new Blob([excelBuffer]);
            FileSaver.saveAs(data, fileObj.fileName);
        }
    }

    const renderFileName = (fileName: string) => {
        const fileNameStr = String(fileName);

        if (fileNameStr.includes('.')) {
            const nameParts = fileNameStr.split('.');
            nameParts.pop();
            return nameParts.join();
        }

        return fileName;
    }

    const renderFileOptions = () => {
        const files = props.files;

        if (!files || (files && !files.length)) return null;

        return files.map((file: any) => {
            const downloadButtonProps = file.downloadUrl ? { href: file.downloadUrl} : {onClick: () => downloadFile(file)};

            return (
                <ListItem>
                  <ListItemIcon>
                    <FileCopy />
                  </ListItemIcon>
                  <ListItemText sx={{ minWidth: '200px' }} primary={renderFileName(file.filename)} />
                
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        {props.delete &&
                            <Button 
                                size="large"
                                startIcon={<DeleteIcon />}
                                onClick={() => deleteFile(file)}
                                sx={{ marginRight: '8px' }}
                            >
                                Delete
                            </Button>
                        }
                        {(props.download || file.downloadUrl) &&
                            <Button 
                                size="large"
                                startIcon={<DownloadIcon />}
                                {...downloadButtonProps}
                            >
                                Download
                            </Button>
                        }            
                                    
                    </ButtonGroup>
              </ListItem>
            );
        });
    }

    return (
        <Box sx={{ width: '100%', maxWidth: props.maxWidth || 360, bgcolor: 'background.paper' }}>

        <List>
            {renderFileOptions()}
        </List>
        </Box>
    );
}