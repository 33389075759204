import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams  } from 'react-router-dom';

import  { getDelegateList, getFPEFormData } from '../../services/fpe-service';

import { FPEContext } from '../../components/fpe/fpeContext';
import { AuthContext } from '../../components/auth/authContext';

import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';


import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import ConfirmationModal from './confirmation-modal';

interface DelegateObj {
    id: string;
    label: string;
}  

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function Delegate() {
    const params = useParams();
    const navigate = useNavigate();

    const { fpeState, fpeDispatch } = useContext(FPEContext);
    const { token } = useContext(AuthContext);

    const [openNotify, setOpenNotify] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const [open, setOpen] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [options, setOptions] = useState<readonly DelegateObj[]>([]);
    const [selectedDelegate, setSelectedDelegate] = useState<DelegateObj | undefined>(undefined);
    const [loadingSearch, setLoadingSearch] = useState(false);

    useEffect(() => {    
        if (loadingSearch) {
          return undefined;
        }
    
        if (String(searchVal).length > 2) {
            (async () => {
                const res = await getDelegateList(searchVal);
    
                setLoadingSearch(true);
                if (res?.status === 200) {
                    setOptions(res?.data ? [...res?.data] : []);
                }
    
                setLoadingSearch(false);
    
            })();
        }
        
    
      }, [searchVal]);

      React.useEffect(() => {
        if (!open) {
          setOptions([]);
        }
      }, [open]);

    useEffect(() => {

        const getFormData = async (formId: string | undefined, year: string | undefined) => {

            const res = await getFPEFormData(formId, year, undefined, token || "", params.form);

            if (res?.status === 200) {
                fpeDispatch({
                    type: "SET_FPE_EVAL_USER_FORM",
                    payload: res.data?.header
                });


                fpeDispatch({
                    type: "SET_FPE_FORM_SECTIONS",
                    payload: res.data?.sections
                });
            }
        }
        
        if (params.formId && params.year) {
            getFormData(params.formId, params.year);
        }

    }, []);

    useEffect(() => {

        setOpenNotify(!errorMessages.length ? false : true);

    }, [errorMessages]);

    const verifySubmittion = () => {
        const errors = validationErrors();
        setErrorMessages(errors);

        if (!errors.length) {
            fpeDispatch({
                type: "SET_OPEN_CONFIRMATION",
                payload: true
            });
        }
    }

    const validationErrors = () => {
        const errors = [];

        
        if (!selectedDelegate) { errors.push("Must chose a delegate before continuing!")}
        
        return errors;
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenNotify(false);
      };

    const onSearchChange = (event: React.SyntheticEvent, value: string, reason: string) => {        
        setSearchVal(value);
    }

    const onDelegateSelect = (event: object, value: any) => {
        setSelectedDelegate(value);
    }

    return (
        <React.Fragment>
            <Grid container spacing={[2, 2]} columnSpacing={[2, 2]}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel 
                            htmlFor="component-outlined"
                            shrink={!!fpeState?.fpeUserEvalData?.name}
                        >
                            Faculty Name
                        </InputLabel>
                                        <OutlinedInput
                                            disabled
                                            id="component-outlined"
                                            required
                                            label="Faculty Name"
                                            readOnly
                                            value={fpeState?.fpeUserEvalData?.name}
                                            notched={!!fpeState?.fpeUserEvalData?.name}
                                        />
                                    </FormControl>
                                </Grid>   
                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">

<Autocomplete
      id="asynchronous-demo"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loadingSearch}
      onInputChange={onSearchChange}
      onChange={onDelegateSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Delegate to a Department Assistant"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />

                                    </FormControl>

                                    
                </Grid>
                <Grid item xs={12}>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                                    <Button 
                                        size="large"
                                        onClick={() => {
                                            navigate(`/fpe/${params.formId}/${params.year}`)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        size="large"
                                        onClick={verifySubmittion}
                                    >
                                        Submit
                                    </Button>
                                </ButtonGroup>    
                </Grid>   
            </Grid>
            <Snackbar open={openNotify} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                        {errorMessages.map(e => (<p>{e}</p>))}
                                </Alert>
                            </Snackbar>
            {selectedDelegate && <ConfirmationModal username={selectedDelegate.id} /> }
        </React.Fragment>
    );
}