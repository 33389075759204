import { createContext, useReducer } from 'react';
import AccountReducer from "./accountReducer";

export type FormLink = {
    form: string;
    href: string;
}

export type EvaluationObj = {
    degree: string;
    department: string;
    division: string;
    departmentChair: string;
    facultyType: string;
    formId: number;
    formUrl: string;
    name: string;
    otherEvaluator: string;
    payCategory: string;
    primaryEvaluator: string;
    rank: string;
    status: string;
    tenureStatus: string;
    year: number;
    links: Array<FormLink>
}

export type InitialState = {
    token?: string,
    title?: string,
    evaluationListData?: Array<EvaluationObj>,
    optOutListData?: []
}

const initialState: InitialState = {
    token: undefined,
    title: undefined,
    evaluationListData: [],
    optOutListData: []
}

export type AccountContextType = {
    accountState: InitialState,
    accountDispatch: React.Dispatch<any>
}

export const AccountContext = createContext<AccountContextType>({ accountState: initialState, accountDispatch: () => null });

export const AccountProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(AccountReducer, initialState);

    return (
        <AccountContext.Provider 
            value={{
               accountState: state,
               accountDispatch: dispatch
            }}
        >
        {children}
        </AccountContext.Provider>
    );
}