import React, { useContext, useEffect, useState } from 'react';

import { FPEContext, FPESubmitOptionsObj } from '../../components/fpe/fpeContext';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function FPESubmitButton ({ submit, signaturesSigned }: { submit: (submitOption?: FPESubmitOptionsObj) => void, signaturesSigned: boolean }) {
    const { fpeState } = useContext(FPEContext);
    
    const [chosenOption, setChosenOption] = useState<FPESubmitOptionsObj | undefined>(undefined);

    useEffect(() => {
        if (!chosenOption && fpeState.submitOptions && fpeState.submitOptions.length === 1) {
            setChosenOption(fpeState.submitOptions[0]);
        }

    }, [fpeState?.submitOptions, chosenOption]);

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value as string;

        if (fpeState.submitOptions) {
            const selectedOption = fpeState.submitOptions.find(obj => value === obj.label);
            setChosenOption(selectedOption);
        }

      };

    const renderSubmitOptions = () => {
        if (fpeState.submitOptions && fpeState.submitOptions.length < 2) return null;

        return (
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Submit form as:</InputLabel>
                <Select
                sx={{ maxWidth: '300px' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={chosenOption?.label}
                label="Age"
                onChange={handleChange}
                >
                    {fpeState.submitOptions &&
                        fpeState.submitOptions.map((obj) => (
                            <MenuItem value={obj.label}>{obj.label}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    }

    const submitIsDisabled = () => {
        if (!fpeState.signaturesData) {
            return !chosenOption;
        }

        if (chosenOption && !chosenOption?.validate) return false;

        const termsQuestionObj = fpeState.signaturesData.questions.find(obj => obj.questionId === 'fpe-self-agreement' && obj.type === 'terms' && obj.required);
        const termsNotChecked = termsQuestionObj?.response !== 'accept';
        
        return !signaturesSigned || (!chosenOption && termsNotChecked);
    }

    return (
        <Grid container sx={{ marginTop: '20px' }} spacing={2}>
            {(fpeState?.submitOptions && fpeState.submitOptions.length > 1) && 
                <Grid item xs={4}>
                    { renderSubmitOptions() }
                </Grid>
            }
                
                <Grid item xs={8}>
                    <Button 
                        sx={{ width: '25ch', margin: '6px 0'}}
                        size="large"
                        onClick={() => submit(chosenOption)}
                        disabled={submitIsDisabled()}
                    >
                        {chosenOption ? chosenOption.label : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
    );
}