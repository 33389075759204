import React, {useRef} from 'react'
import Input from '@mui/material/Input';

export const FileUpload = ({onFileSelectError, onFileSelectSuccess}) => {
    const fileInput = useRef(null)

    const handleFileInput = (e) => {
      // handle validations
      const file = e.target.files[0];
      onFileSelectSuccess(file);
    };

    return (
        <div className="file-uploader">
            <Input type="file" onChange={handleFileInput} />
        </div>
    )
}
