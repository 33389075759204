import { callApi } from './general';

export const getFPEFormData = async (formId: string | undefined, year: string | undefined, url: string | undefined, token: string, form?: string) => {
    if (url) {
        return await callApi(url, 'get');
    }

    return await callApi(`/form/${formId}/${year}/${form}`, 'get', { headers: { 'Authorization': token } });
}

export const submitFPEFormData = async (link: string) => await callApi(link, 'post');

export const uploadFile = async (url: string, formData: any) => callApi(url, 'post', formData);

export const getFileList = async (formId: number | undefined, formType: string | undefined, year: string | undefined, section?: string) => await callApi(`/attach/list/${year}/${formType}/${formId}/${section}`, 'get');

export const downloadFile = async (formId: number | undefined, formType: string | undefined, year: string | undefined, section: string, fileName: string) => await callApi(`/attach/download/${year}/${formType}/${formId}/${section}/${fileName}`, 'get', { responseType: 'blob' });

export const removeFile = async (url: string ) => await callApi(url, 'post');

export const getDelegateData = async (formId: string | undefined, year: string | undefined, form: string | undefined, username: string | undefined) => await callApi(`/delegate/${formId}/${year}/${form}/add/${username}`, 'get');

export const getDelegateList = async (text: string) => await callApi(`/delegate/search?q=${text}`, 'get');

export const submitDelegationFormData = async (username: string, formId?: string, year?: string, form?: string) => await callApi(`/delegate/${formId}/${year}/${form}/add/${username}`, 'post');

//export const sendQuestionData = async (questionLink: string, text: string, content: string) => await callApi(questionLink, 'post', { text: text, html: content});